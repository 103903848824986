
import React from "react";

import { GameSettings } from "../../../models/poker";

export const parseIntSafe = (x: string) => {
  const n = parseInt(x);
  return isNaN(n) ? 0 : n;
}


export function TableSettings(props: { close: () => void, initialSettings: GameSettings, onSave: (_: GameSettings) => void }) {
  const [settings, setSettings] = React.useState(props.initialSettings);

  const valid = settings.smallBlindAmount > 0 && settings.bigBlindAmount > 0 && settings.bigBlindAmount > settings.smallBlindAmount;

  const close = () => {
    setSettings(props.initialSettings);
    props.close();
  }

  const save = () => {
    if (valid) {
      props.onSave(settings);
      props.close();
    }
  }

  return (
    <>
      <div className="overlay-common">

        <h1 className="font-bold text-center text-lg mt-2 mb-3">table settings</h1>

        <div className="grid grid-cols-1 gap-1 divide-gray-300">

          <div className="setting-group">
            <span>small blind</span>
            <input
              value={settings.smallBlindAmount || ''}
              onFocus={e => e.currentTarget.select()}
              inputMode="numeric"
              onInput={e => setSettings({ ...settings, smallBlindAmount: parseIntSafe(e.currentTarget.value) })}
            />
          </div>

          <div className="setting-group">
            <span>big blind</span>
            <input
              value={settings.bigBlindAmount || ''}
              onFocus={e => e.currentTarget.select()}
              inputMode="numeric"
              onInput={e => setSettings({ ...settings, bigBlindAmount: parseIntSafe(e.currentTarget.value) })}
            />
          </div>

          <div className="setting-group">
            <span>player starts with</span>
            <input
              value={settings.defaultStartingAmount || ''}
              onFocus={e => e.currentTarget.select()}
              inputMode="numeric"
              onInput={e => setSettings({ ...settings, defaultStartingAmount: parseIntSafe(e.currentTarget.value) })}
            />
          </div>

          <div className="setting-group">
            <span>strict minimum raise</span>
            <span>respect minimum raise rule in poker</span>
            <button
              onClick={() => setSettings({ ...settings, strictMinRaise: !settings.strictMinRaise })}
            >
              {settings.strictMinRaise ? 'on' : 'off'}
            </button>
          </div>

        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div className="button3 w-full col-span-1" onClick={close}>Discard</div>
        <div className={`button3 w-full col-span-1 green ${valid || 'disabled'}`} onClick={save}>Save</div>
      </div>
    </>
  )
}
