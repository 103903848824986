import React, { ReactNode } from "react";
import { motion, motionValue, animate, MotionStyle } from "framer-motion"


const DURATION_GLOBAL_MODIFIER = 1;

interface Props {
  children: ReactNode,
  duration?: number,
  className?: string,
};

type animatedProperties = 'x' | 'y' | 'opacity' | 'backgroundColor';

export class AnimatedText extends React.Component<Props> {
  state = {
    text: '',
  };

  containerWidth = motionValue(0);
  prevStyles: { [x in animatedProperties]: any } = {
    x: motionValue(0),
    y: motionValue(0),
    opacity: motionValue(1),
    'backgroundColor': motionValue('transparent'),
  }
  nextStyles: { [x in animatedProperties]: any } = {
    x: motionValue(0),
    y: motionValue(0),
    opacity: motionValue(0),
    'backgroundColor': motionValue('transparent'),
  }

  prevRef: HTMLSpanElement | null = null;
  nextRef: HTMLSpanElement | null = null;

  prevStaticStyles: MotionStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    overflow: 'visible',
    top: 0,
    left: 0,
    transform: 'translateX(50%)',
    transition: 'backgroundColor 1s',
  }
  nextStaticStyles: MotionStyle = {
    ...this.prevStaticStyles,
    position: 'relative',
  }

  componentDidMount(): void {
    this.setState({ text: this.props.children }, () => {
      animate(this.containerWidth, this.prevRef?.getBoundingClientRect().width || 0);
    });

    window.document.addEventListener('vchips_animatedTextReload', () => {
      // this.componentDidUpdate({ children: this.state.text }, {}, {});
      this.containerWidth.set(this.nextRef?.getBoundingClientRect().width || 0);
    });
  }

  // TODO: Animate height?

  get prevWidth() {
    return this.prevRef?.getBoundingClientRect().width || 0
  }
  get nextWidth() {
    return this.nextRef?.getBoundingClientRect().width || 0
  }

  get height() {
    return Math.max(this.prevRef?.getBoundingClientRect().height || 0, this.nextRef?.getBoundingClientRect().height || 0);
  }

  async componentDidUpdate(prevProps: Readonly<{ children: ReactNode; }>, prevState: Readonly<{}>, snapshot?: any) {
    // TODO: prevent unnecessary updates (blinking). The children check are different but the text is the same.
    //       probably will use a key to prevent this
    if (prevProps.children === this.props.children)
      return;

    const duration = (this.props.duration ?? 0.5) * DURATION_GLOBAL_MODIFIER;

    const prevText = this.state.text;
    const nextText = this.props.children;
    const areBothNumbers = !isNaN(Number(prevText)) && !isNaN(Number(nextText));

    const properties: { [x in animatedProperties]?: Record<'prev' | 'next', [any, any]> } = {
      opacity: { prev: [1, 0], next: [0, 1] },
    }

    if (areBothNumbers) {
      if (Number(prevText) === Number(nextText)) {
        // This should not happen, but it does happen on rapid updates
        // console.log(prevProps.children, this.props.children)
        // To be investigate later if it's a problem
        return;
      } else if (Number(prevText) < Number(nextText)) {
        properties.y = { prev: [0, this.height], next: [-this.height, 0] };
        properties["backgroundColor"] = { prev: ['#00FF0000', '#00FF00A0'], next: ['#00FF00A0', '#00FF00A0'] };
      } else if (Number(prevText) > Number(nextText)) {
        properties.y = { prev: [0, -this.height], next: [this.height, 0] };
        properties["backgroundColor"] = { prev: ['#FF000000', '#FF0000A0'], next: ['#FF0000A0', '#FF0000A0'] };
      } else {
        debugger;
      }
    }

    Object.entries(properties).forEach(([key, { prev, next }]) => {
      this.prevStyles[key as animatedProperties].set(prev[0]);
      this.nextStyles[key as animatedProperties].set(next[0]);
    });


    if (this.prevWidth < this.nextWidth) {
      // If the next text is longer, we need to animate the container width first (to avoid clipping)
      // Achieved by animating the width faster
      await Promise.all([
        animate(this.containerWidth, this.nextWidth, { duration: duration * 0.2 }),
        ...Object.entries(properties).flatMap(([key, { prev, next }]) => [
          animate(this.prevStyles[key as animatedProperties], prev[1], { ease: 'easeIn', duration: duration * 0.5 + (key === 'backgroundColor' ? 1.5 : 0), delay: duration * 0.2 }),
          animate(this.nextStyles[key as animatedProperties], next[1], { ease: 'easeOut', duration: duration * 0.5 + (key === 'backgroundColor' ? 1.5 : 0), delay: duration * 0.4 }),
        ]),
      ]);
    } else {
      // Otherwise, we can start animating the text immediately
      await Promise.all([
        animate(this.containerWidth, this.nextWidth, { duration: duration * 0.2 }),
        ...Object.entries(properties).flatMap(([key, { prev, next }]) => [
          animate(this.prevStyles[key as animatedProperties], prev[1], { ease: 'easeIn', duration: duration * 0.5 + (key === 'backgroundColor' ? 1.5 : 0), delay: duration * 0.2 }),
          animate(this.nextStyles[key as animatedProperties], next[1], { ease: 'easeOut', duration: duration * 0.5 + (key === 'backgroundColor' ? 1.5 : 0), delay: duration * 0.4 }),
        ]),
      ]);
    }

    Object.entries(properties).forEach(([key, { prev, next }]) => {
      this.prevStyles[key as animatedProperties].set(prev[0]);
      this.nextStyles[key as animatedProperties].set(next[0]);
    });

    this.setState({ text: this.props.children });
  }

  render() {
    return (
      <div className={this.props.className ?? ''}>
        <span ref={(r) => this.prevRef = r} className="invisible absolute">{this.state.text}</span>
        <span ref={(r) => this.nextRef = r} className="invisible absolute">{this.props.children}</span>
        <motion.div className="animated-text-debug relative box-content overflow-x-clip overflow-y-clip" style={{ width: this.containerWidth }}>
          <motion.div className="prev" style={{ ...this.prevStyles, ...this.prevStaticStyles }}>
            <div style={{ width: 'fit-content' }}>{this.state.text}</div>
          </motion.div>
          <motion.div className="next" style={{ ...this.nextStyles, ...this.nextStaticStyles }}>
            <div style={{ width: 'fit-content' }}>{this.props.children}</div>
          </motion.div>
        </motion.div>
      </div>
    )
  }
}

