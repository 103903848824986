import { FC } from 'react';
import HomePage from './react/HomePage';
import MultiScreenPage from './react/MultiScreenPage';
import JoinPage from './react/GamePage';
import TestPage from './react/TestPage';

const App: FC = () => {
  const url = document.URL;
  const { pathname } = new URL(url);

  let ComponentToRender;

  if (pathname === '/') {
    ComponentToRender = HomePage;
  } else if (pathname.match(/\/\d+/)) {
    ComponentToRender = JoinPage;
  } else if (pathname === '/demo') {
    ComponentToRender = MultiScreenPage;
  } else if (pathname === '/test') {
    ComponentToRender = TestPage;
  } else {
    ComponentToRender = HomePage;
  }

  return (
    <ComponentToRender />
  );
}


export default App;
