
import { Point, Vector, Box } from '@flatten-js/core';

import { AnimationPlaybackControls, MotionValue, PanInfo, ValueAnimationTransition, animate, motion, motionValue } from "framer-motion"
import { SpritId } from "../view/SpritView";
import { idFor } from "../view/types";


export enum Snap {
  top = 1,
  bottom = 2,
  left = 4,
  right = 8,
  middle = 16,
  center = middle + middle,
  top_left = top + left,
  top_right = top + right,
  bottom_left = bottom + left,
  bottom_right = bottom + right,
  middle_left = middle + left,
  middle_right = middle + right,
  middle_top = middle + top,
  middle_bottom = middle + bottom,
}

type AnimationOption = 'spring' | 'tween' | 'inertia' | 'easeInOut' | undefined;

export default class Sprit {
  id: SpritId;
  static Snap = Snap;
  box: Box;
  MVs = {
    x: motionValue(0),
    y: motionValue(0),
    w: motionValue(0),
    h: motionValue(0),
    scale: motionValue(1),
    bg: motionValue('rgba(0,0,0,0)'),
    borderColor: motionValue('rgba(0,0,0,0)'),
    borderWidth: motionValue(0),
    opacity: motionValue(1),
  } as const;

  variants: Record<string, Record<string, number | string>> = {};
  drag = true;
  currentVariant: string | null = null;
  zIndex = 100;
  additionalConstantStyles = {};

  // TODO[Sprit/vanish]: give ability to customize animation for disappearing

  async toVariant(smooth: boolean, variant: string) {
    if (smooth) {
      return await this.animateToVariant(variant);
    } else {
      this.setToVariant(variant);
    }
  }

  async animateToVariant(variant: string) {
    if (!(variant in this.variants)) {
      throw Error('Variant not found: ' + variant);
    }
    if (variant === this.currentVariant) return;
    this.currentVariant = variant;
    await Promise.all(
      Object.keys(this.variants[variant]).map(key => {
        if (key === 'borderWidth')
          return animate((this.MVs as any)[key], this.variants[variant][key], { type: 'tween', duration: 0.1 })
        else
          return animate((this.MVs as any)[key], this.variants[variant][key], { type: 'spring', stiffness: 600, damping: 80, duration: 1 })
      })
    );
  }

  resetVariant() {
    const initValues = {
      bg: 'rgba(0,0,0,0)',
      borderColor: 'rgba(0,0,0,0)',
      borderWidth: 0,
      opacity: 1,
    };
    Object.keys(initValues).map(key =>
      (this.MVs as any)[key].set((initValues as any)[key])
    )
  }

  async resetVariantAnimate(): Promise<void> {
    const initValues = {
      bg: 'rgba(0,0,0,0)',
      borderColor: 'rgba(0,0,0,0)',
      borderWidth: 0,
      opacity: 1,
    };
    await Promise.all(Object.keys(initValues).map(key =>
      animate((this.MVs as any)[key], (initValues as any)[key])
    ))
  }

  setToVariant(variant: string) {
    this.currentVariant = variant;
    Object.keys(this.variants[variant]).forEach(key => {
      (this.MVs as any)[key].set(this.variants[variant][key]);
    });
  }

  private uniqueViewId;
  constructor(boxOrSize: Box | { x: number, y: number }, uniqueViewId: string, id: SpritId) {
    if (!(boxOrSize instanceof Box)) {
      boxOrSize = new Box(0, 0, boxOrSize.x, boxOrSize.y);
    }
    this.uniqueViewId = uniqueViewId;
    this.box = boxOrSize;
    this.MVs.x.set(boxOrSize.xmin);
    this.MVs.w.set(boxOrSize.width);
    this.MVs.y.set(boxOrSize.ymin);
    this.MVs.h.set(boxOrSize.height);
    this.id = id;
  }

  async animateToRandomColor(): Promise<void> {
    const color = `hsla(${Math.random() * 360}, 50%, 50%, .5)`;

    return await animate(this.MVs.bg, color);
  }

  toNodeId() {
    return idFor(this.uniqueViewId, this);
  }

  private previousAnimateX?: AnimationPlaybackControls;
  private previousAnimateY?: AnimationPlaybackControls;
  async moveOld(v: Vector, instant = false) {
    this.box = this.box.translate(v);
    this.previousAnimateX && this.previousAnimateX.stop();
    this.previousAnimateY && this.previousAnimateY.stop();

    if (instant) {
      this.MVs.x.set(this.box.xmin);
      this.MVs.y.set(this.box.ymin);
    } else {
      this.previousAnimateX = animate(this.MVs.x, this.box.xmin, { type: 'spring', stiffness: 600, damping: 80 });
      this.previousAnimateY = animate(this.MVs.y, this.box.ymin, { type: 'spring', stiffness: 600, damping: 80 });
      await Promise.all([this.previousAnimateX, this.previousAnimateY]);
    }
  }
  async move(v: Vector, instant: boolean, animationOption?: AnimationOption): Promise<void> {
    this.box = this.box.translate(v);
    this.previousAnimateX && this.previousAnimateX.stop();
    this.previousAnimateY && this.previousAnimateY.stop();

    if (instant) {
      this.MVs.x.set(this.box.xmin);
      this.MVs.y.set(this.box.ymin);
    } else {
      let animateOption: ValueAnimationTransition<number>;

      switch (animationOption) {
        case 'spring':
          animateOption = { type: 'spring', stiffness: 600, damping: 80, duration: 1 };
          break;
        case 'tween':
          animateOption = { type: 'tween', duration: 1 };
          break;
        case 'inertia':
          animateOption = { type: 'inertia', duration: 1 };
          break;
        default:
        case 'easeInOut':
          animateOption = { type: 'tween', ease: 'easeInOut', duration: 1 };
          break;
      }

      this.previousAnimateX = animate(this.MVs.x, this.box.xmin, animateOption);
      this.previousAnimateY = animate(this.MVs.y, this.box.ymin, animateOption);
      await Promise.all([this.previousAnimateX, this.previousAnimateY]);
    }
  }

  cAt(target: Point) {
    const diff = this.calculateDiff(target, Snap.center);
    this.box = this.box.translate(diff);
    this.previousAnimateX && this.previousAnimateX.stop();
    this.previousAnimateY && this.previousAnimateY.stop();
    this.MVs.x.set(this.box.xmin);
    this.MVs.y.set(this.box.ymin);
    return this;
  }

  // TODO[cleanup/Sprit *o*]: remove if not used.
  oAt(target: Point) {
    const diff = this.calculateDiff(target, Snap.top_left);
    this.box = this.box.translate(diff);
    this.previousAnimateX && this.previousAnimateX.stop();
    this.previousAnimateY && this.previousAnimateY.stop();
    this.MVs.x.set(this.box.xmin);
    this.MVs.y.set(this.box.ymin);
    return this;
  }

  async to(target: Point, options: { snap: Snap, instant: boolean, animationOption?: AnimationOption }) {
    const diff = this.calculateDiff(target, options.snap);
    return await this.move(diff, options.instant, options.animationOption);
  }

  async moveO(target: Point) { return await this.to(target, { snap: Snap.top_left, instant: true }); }
  async moveC(target: Point) { return await this.to(target, { snap: Snap.center, instant: true }); }
  async animateO(target: Point, option?: AnimationOption) { return await this.to(target, { snap: Snap.top_left, instant: false, animationOption: option }); }
  async animateC(target: Point, option?: AnimationOption) { return await this.to(target, { snap: Snap.center, instant: false, animationOption: option }); }

  async O(smooth: boolean, target: Point, option: AnimationOption) {
    if (smooth) await this.animateO(target, option);
    else this.moveO(target);
  }

  async C(smooth: boolean, target: Point, option: AnimationOption) {
    if (smooth) await this.animateC(target, option);
    else this.moveC(target);
  }

  async moveToOld(target: Point, snapPoint: Snap = Snap.top_left, instant = false) {
    return await this.moveOld(this.calculateDiff(target, snapPoint), instant);
  }

  private calculateDiff(target: Point, snapPoint: Snap): Vector {
    let xDiff, yDiff;
    if (snapPoint & Snap.left) {
      xDiff = target.x - this.box.xmin;
    } else if (snapPoint & Snap.right) {
      xDiff = target.x - this.box.xmax;
    } else if (snapPoint & Snap.middle) {
      xDiff = target.x - this.box.center.x;
    } else {
      xDiff = 0;
    }

    if (snapPoint & Snap.top) {
      yDiff = target.y - this.box.ymin;
    } else if (snapPoint & Snap.bottom) {
      yDiff = target.y - this.box.ymax;
    } else if (snapPoint & Snap.middle) {
      yDiff = target.y - this.box.center.y;
    } else {
      yDiff = 0;
    }

    if (snapPoint === Snap.center) {
      xDiff = target.x - this.box.center.x;
      yDiff = target.y - this.box.center.y;
    }

    const diff = new Vector(xDiff, yDiff);
    return diff;
  }

  handleDrag = async (info: PanInfo, hasEnded: boolean) => {
    // if (info.delta.x < 0.0001 && info.delta.y < 0.0001 && !hasEnded) return;
    if (this.onDragHandler) {
      this.onDragHandler(new Point(this.box.center.x + info.offset.x, this.box.center.y + info.offset.y), hasEnded);
    } else {
      if (hasEnded) {
        await this.animateC(this.box.center, 'spring')
      }
    }
  };

  onDragHandler?: (newCenter: Point, hasEnded: boolean) => void;
  onClickHandler?: () => void;

  toNode() {
    const transform = {
      "x": "x",
      "y": "y",
      "w": "width",
      "h": "height",
      "bg": "backgroundColor",
    } as const;

    const transformed: Partial<Record<(typeof transform)[keyof typeof transform], MotionValue>> = {};
    for (const key in this.MVs) {
      if (key in transform) {
        // @ts-expect-error ignore
        transformed[transform[key]] = this.MVs[key];
      } else {
        // @ts-expect-error ignore
        transformed[key] = this.MVs[key];
      }
    }

    return (
      <motion.div
        drag={this.drag}
        dragMomentum={false}
        style={{
          ...transformed,
          position: 'absolute',
          zIndex: this.zIndex,
          ...this.additionalConstantStyles,
          ...(this.onClickHandler ? { cursor: 'pointer' } : {}),
        }}
        id={idFor(this.uniqueViewId, this.id)}
        onTap={() => { if (this.onClickHandler) this.onClickHandler(); }}
        onDrag={(event, info) => { this.handleDrag(info, false); }}
        onDragEnd={(event, info) => { this.handleDrag(info, true); }}
      >
      </motion.div>
    )
  }
}

export const Layout = async (sprits: Sprit[], boundingBox: Box, playerOrInsert: 'player' | 'insert' = 'player', playerSize: Box) => {
  const PLAYER_SIZE: Box = playerSize;
  const INSERT_SIZE: Box = new Box(0, 0, 30, 30);
  const COUNT = sprits.length;
  const RADIUS_X = boundingBox.width / 2 - PLAYER_SIZE.width / 2;
  const RADIUS_Y = boundingBox.height / 2 - PLAYER_SIZE.height / 2;
  const itemSize = playerOrInsert === 'insert' ? INSERT_SIZE : PLAYER_SIZE;
  const angleOffset = playerOrInsert === 'insert' ? 1 : 0;

  return Promise.all(
    sprits.map(async (sprit, i) => {
      const x = RADIUS_X * Math.cos(Math.PI * (angleOffset + i * 2) / COUNT + Math.PI / 2) + boundingBox.width / 2 - itemSize.width / 2;
      const y = RADIUS_Y * Math.sin(Math.PI * (angleOffset + i * 2) / COUNT + Math.PI / 2) + boundingBox.height / 2 - itemSize.height / 2;
      return sprit.moveToOld(new Point(x, y));
    })
  );
};

export const LayoutPlayers = async (sprits: Sprit[], boundingBox: Box) => {
  return Layout(sprits, boundingBox, 'player', sprits[0].box);
}
