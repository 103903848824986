import React from 'react';
import { Sprit } from '../components';

import Assert from "../../models/assert";
import { Point } from '@flatten-js/core';
import { idFor } from './types';
import { animate } from 'framer-motion';

export type SpritId =
  // GameView
  | { name: 'pot' }
  | { name: 'current-player-focus' }
  | { name: 'chip-to-pot' }
  // TableView
  | { insert: string }
  | { name: 'new-player-box' }
  // Shared
  | { player: string }
  ;

interface Props {
  uniqueViewId: string;
}

export class SpritView extends React.Component<Props> {
  state = {
    sprits: {} as Record<string, Sprit>
  }

  forceUpdateAsync = () => new Promise<void>((resolve) => this.forceUpdate(resolve));

  setStateAsync = (state: any) => new Promise<void>((resolve) => this.setState(state, resolve));

  async remove(id: SpritId) {
    // if ('player' in id) {
    //   await animate(this.state.sprits[idFor(this.props.uniqueViewId, id)].MVs.scale, 0, { duration: 0.2 });
    // }
    delete this.state.sprits[idFor(this.props.uniqueViewId, id)];
    await this.setStateAsync({ sprits: this.state.sprits });
    // await this.forceUpdateAsync();
  }

  get(id: SpritId): Sprit {
    const result = this.state.sprits[idFor(this.props.uniqueViewId, id)];
    // Assert.expect(result).beTruthy(`Sprit not found for ${idFor(this.props.uniqueViewId, id)}`);
    return result;
  }

  getAll(): Sprit[] {
    return Object.values(this.state.sprits);
  }

  private inBatch = false;
  private batchSpritTouched = false;
  private batchSprits: Record<string, Sprit> = {};
  private batchPromises: (() => void | Promise<void>)[] = [];
  private untouchedPlayers: Record<string, Sprit> = {};
  batchStart() {
    if (this.inBatch) {
      throw new Error('Batch already started');
    }
    this.inBatch = true;
    this.batchSpritTouched = false;
    this.batchSprits = { ...this.state.sprits };
    this.batchPromises = [];

    this.untouchedPlayers = { ...this.batchSprits };
  }

  async batchEnd() {
    if (!this.inBatch) {
      throw new Error('Batch not started');
    }
    this.inBatch = false;
    if (this.batchSpritTouched)
      await new Promise<void>(r => this.setState({ sprits: this.batchSprits }, r));
    await Promise.all(this.batchPromises.map(p => p()));

    return this.untouchedPlayers;
  }

  findOrCreate(id: SpritId, options: SpritInitOptions): Sprit {
    Assert.expect(this.inBatch).beTruthy('Must be in batch');

    let s: Sprit;
    let isNew = false;
    if (this.batchSprits[idFor(this.props.uniqueViewId, id)]) {
      s = this.batchSprits[idFor(this.props.uniqueViewId, id)];
      isNew = false;
      delete this.untouchedPlayers[idFor(this.props.uniqueViewId, id)];
    }
    else {
      s = new Sprit({ x: options.size.x, y: options.size.y }, this.props.uniqueViewId, id);
      this.batchSprits[idFor(this.props.uniqueViewId, id)] = s;
      isNew = true;
      this.batchSpritTouched = true;
    }

    if (options.c) {
      s.cAt(new Point(options.c.x, options.c.y));
    }
    if (options.o) {
      s.oAt(new Point(options.o.x, options.o.y));
    }

    const newVariants = options.variants ?? {};
    const variantsChanged = JSON.stringify(newVariants) !== JSON.stringify(s.variants);
    if (variantsChanged)
      s.variants = newVariants;

    if (variantsChanged) {
      // // OPTION 1: hard reset variant
      s.resetVariant();

      // OPTION 2: animate variant
      // this.batchPromises.push(() => s.resetVariantAnimate());
    }


    if (isNew || variantsChanged) {
      if (options.initialVariant) {
        s.setToVariant(options.initialVariant);
        // this.batchPromises.push(() => s.animateToVariant(options.initialVariant!));
      } else {
        s.currentVariant = null;
      }
    }
    if (options.dragHandler) {
      s.drag = true;
      s.onDragHandler = options.dragHandler;
    } else {
      s.drag = false;
      s.onDragHandler = undefined;
    }

    if (options.clickHandler) {
      s.onClickHandler = options.clickHandler;
    } else {
      s.onClickHandler = undefined;
    }

    if (isNew && options.ifNew)
      this.batchPromises.push(() => options.ifNew?.(s as Sprit));
    if (!isNew && options.ifOld)
      this.batchPromises.push(() => options.ifOld?.(s as Sprit));

    return s;
  }

  render() {
    return (
      Object.keys(this.state.sprits).map((key) =>
        <React.Fragment key={key}>{this.state.sprits[key].toNode()}</React.Fragment>
      )
    );
  }
}

export interface SpritInitOptions {
  size: { x: number, y: number },
  c?: Point,
  o?: Point,
  variants?: Record<string, Record<string, number | string>>,
  initialVariant?: string,
  dragHandler?: (newCenter: Point, hasEnded: boolean) => void,
  clickHandler?: () => void,
  ifNew?: (s: Sprit) => void | Promise<void>,
  ifOld?: (s: Sprit) => void | Promise<void>,

  // TODO[Sprit/vanish]: give ability to customize animation for disappearing
}
