import React from "react";
import { PLAYER_SIZE, portalForIf } from "../types";

import { Game, Table } from "../../../models/poker";
import { Sprit } from "../../components";
import { PlayerCardForShowdown } from "../PlayerCard";

import { Box } from '@flatten-js/core';
import Assert from "../../../models/assert";


import { SceneProps, SceneInterface } from '../types'
import { SpritView } from "../SpritView";
import { layoutPlayersForResult } from "../DResult/ResultView";
import { GlobalSettings } from "../GlobalSettings";
import { CentralManager } from "../CentralManager";

interface State {
  sceneLoaded: boolean,
  splitMode: boolean,
  selectedWinners: string[],
  overlay: 'global' | null,
}

/*
UI:

Premise: only one pot needed to be settled

Two versions:
Version A: Only two player involved
- Show buttons for player A, player B, Split
- All three button --> ResultView

Version B: Three or more players involved
- Show buttons for each player, and Split
- Buttons for each player --> ResultView
- Split --> trigger additional state.
  - the split button disappears, "submit" button appears
  - user can click on each player to toggle win state
  - when submit is clicked --> ResultView
*/

export class ShowdownSimpleView extends React.Component<SceneProps, State> implements SceneInterface {
  canvas: Box;
  table: Table;
  cm: CentralManager;
  sv: SpritView;

  static shouldBeUsed(g: Game): boolean {
    Assert.expect(g.currentRound).eq('showdown');

    if (g.pots.length === 1) return true;
    if (g.pots.filter(p => p.players.length > 1).length === 1) return true;
    return false;
  }

  setStateAsync = (state: any) => new Promise<void>((resolve) => this.setState(state, resolve));

  state: State = {
    sceneLoaded: false,
    splitMode: false,
    selectedWinners: [],
    overlay: null,
  }

  get g() {
    Assert.expect(this.table.currentGame).beTruthy();
    return this.table.currentGame!;
  }

  get simpleMode() {
    return this.g.pots[0].players.length === 2;
  }

  get player() { return this.props.playerId; }

  constructor(props: SceneProps) {
    super(props);
    this.canvas = props.initialCanvas;
    this.table = props.initialTable;
    this.cm = props.cm;
    this.sv = props.spritView;
  }

  playerSprits: Sprit[] = [];
  sceneLoad = async () => {
    this.sv.batchStart();
    this.playerSprits = this.g.pots[0].players.map(p => {
      const x = this.sv.findOrCreate(
        { player: p },
        {
          size: PLAYER_SIZE,
          ifNew: sprit => { sprit.cAt(this.canvas.center) },
          variants: {
            'normal': {
              bg: '#88888808'
            },
            'winner': {
              bg: '#22c55e19'
            },
          },
          initialVariant: 'normal',
          clickHandler: () => { this.onPlayerClicked(p) },
        }
      );
      return x;
    })

    await this.sv.batchEnd();
    await this.setStateAsync({ sceneLoaded: true });
  };

  sceneLayout = async (canvas: Box, smooth: boolean) => {
    this.canvas = canvas;
    await layoutPlayersForResult(this.playerSprits, canvas);
  }

  sceneUnload = async () => {
    await this.setStateAsync({ sceneLoaded: false });
    await Promise.all(this.playerSprits.map(sprit => sprit.resetVariant()));
  };

  onTableUpdate = async (table: Table) => {
    this.table = table;
    await this.sceneLayout(this.canvas, true);
  }

  onPlayerClicked = async (p: string) => {
    if (this.simpleMode) {
      this.g.potResult![0] = [p];
      this.cm.triggerTableUpdate(this.table, 'select winner: ' + p);
      return;
    }

    if (this.state.splitMode) {
      const selected = this.state.selectedWinners;
      if (selected.includes(p)) {
        this.sv.get({ player: p }).animateToVariant('normal');
        this.setState({ selectedWinners: selected.filter(x => x !== p) });
      } else {
        this.sv.get({ player: p }).animateToVariant('winner');
        this.setState({ selectedWinners: [...selected, p] });
      }
    } else {
      this.setState({ selectedWinners: [p] });
      this.g.potResult![0] = [p];
      this.cm.triggerTableUpdate(this.table, 'select winner: ' + p);
    }
  }

  onSplitClicked = () => {
    Assert.expect(this.simpleMode).beTruthy();
    this.g.potResult![0] = this.g.pots[0].players;
    this.cm.triggerTableUpdate(this.table, 'select winners: split');
  }

  onSaveClicked = () => {
    Assert.expect(this.state.splitMode).beTruthy();
    this.g.potResult![0] = this.state.selectedWinners;
    this.cm.triggerTableUpdate(this.table, 'select winners: ' + this.state.selectedWinners.join(', '));
  }

  showOverlay = async (view: State['overlay']) => {
    if (view) {
      await this.setStateAsync({ overlay: view });
      await this.cm.setOverlayVisibility(true);
    } else {
      await this.cm.setOverlayVisibility(false);
    }
  }


  render() {
    const { GlobalButton } = this.props;
    const portalFor = portalForIf(this.props.uniqueViewId, this.state.sceneLoaded);

    return [
      portalFor('view-before')(
        <div className="view-before">
          <div className="text-and-settings">
            <GlobalButton onClick={() => this.showOverlay('global')} />
            <div className="prompt-text" onClick={() => { this.setState({ splitMode: false }) }}>
              {
                this.simpleMode ?
                  'Select the winner(s)'
                  :
                  'Select the winner'
              }
            </div>
          </div>
        </div>
      )
      ,
      portalFor('view-after')(
        <div className="grid grid-cols-2 gap-2 px-2 justify-items-center">
          {
            this.simpleMode ?
              <div
                className="button3 w-full col-span-2"
                onClick={() => { this.onSplitClicked(); }}
              >tie – split the pot</div>
              :
              this.state.splitMode ?
                <div
                  className={`button3 w-full col-span-2 green ${this.state.selectedWinners.length === 0 && 'disabled'}`}
                  onClick={() => { this.onSaveClicked(); }}
                >continue</div>
                :
                <div
                  className="button3 w-full col-span-2"
                  onClick={() => { this.setState({ splitMode: true }) }}
                >tie? – select winners instead</div>
          }
        </div>
      )
      ,
      ...this.g.pots[0].players.map(player =>
        portalFor({ player })(
          <PlayerCardForShowdown
            player={player}
            winner={this.state.selectedWinners.includes(player)}
          />
        )
      )
      ,
      portalFor('overlay')(
        <>
          {
            this.state.overlay === 'global' &&
            <GlobalSettings sharedOptions={this.props.globalSettings} close={() => this.showOverlay(null)}>

            </GlobalSettings>
          }
        </>
      )
    ];
  }
}
