import './firebase.js';

import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import { PostHogProvider } from 'posthog-js/react';

import * as Sentry from "@sentry/react";
import posthog from 'posthog-js';

Sentry.init({
  dsn: "https://972b82d8c5b405c9a845976503176a43@o4508373785051136.ingest.us.sentry.io/4508373786820608",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // https://posthog.com/docs/libraries/sentry
    posthog.sentryIntegration({
      organization: 'vchipsapp',
      projectId: 4508373786820608,
      severityAllowList: ['error', 'info'] // optional: here is set to handle captureMessage (info) and captureException (error)
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createRoot(document.getElementById('root')!).render(
  <PostHogProvider
    apiKey={import.meta.env.VITE_POSTHOG_API_KEY!}
    options={{ api_host: import.meta.env.VITE_POSTHOG_HOST, ui_host: import.meta.env.VITE_POSTHOG_UI_HOST }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </PostHogProvider>
)
