import React from "react";
import { SpritView } from "./SpritView";

import { Table } from "../../models/poker";

import { Box } from "@flatten-js/core";
import { idFor, SceneId, SceneInterface, SceneProps } from "./types";
import { GameView } from "./BGame/GameView";
import { TableView } from "./ATable/TableView";
import { ShowdownComplexView } from "./CShowdown/ShowdownComplexView";
import { ShowdownSimpleView } from "./CShowdown/ShowdownSimpleView";
import { ResultView } from "./DResult/ResultView";
import { getQueryParam } from "../display/ClientController";
import { motion } from "framer-motion";
import Settings from "@mui/icons-material/Settings";
import { GlobalOptions } from "./GlobalSettings";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Sync from '@mui/icons-material/Sync';


interface Props {
  uniqueViewId: string;
  updateOtherClients: (table: Table, reason: string) => void;
  managedPlayerId?: string;
  tableId: string;
  disconnect: () => void;
  reconnect: () => void;
  connected: boolean;
  connecting: boolean;
  undoRedo: (undo: boolean) => void;
  getUndoRedoInfo: () => Promise<{ undo: string | null, redo: string | null }>;
  singlePhoneMode: boolean;
}

const sceneLookupTable: Record<SceneId, any> = {
  'table': TableView,
  'game': GameView,
  'showdown-simple': ShowdownSimpleView,
  'showdown-complex': ShowdownComplexView,
  'result': ResultView,
}

function getSceneForTable(table: Table): SceneId {
  if (!table.currentGame) return 'table';
  if (table.currentGame.currentRound === 'showdown') {
    if (table.currentGame.isAllPotsSettled) {
      return 'result';
    } else {
      if (ShowdownSimpleView.shouldBeUsed(table.currentGame)) {
        return 'showdown-simple';
      }
      return 'showdown-complex';
    }
  }
  return 'game';
}



const defaultGlobalOptions: GlobalOptions = {
  font: '100%',
  theme: 'auto',
  sleep: 'on',
}

export class CentralManager extends React.Component<Props> {

  spritViewContainerRef = React.createRef<HTMLDivElement>();
  spritViewRef = React.createRef<SpritView>();
  rootContainerRef = React.createRef<HTMLDivElement>();

  sceneRef = React.createRef<SceneInterface>();

  state = {
    currentScene: null as SceneId | null,
    playerId: null as string | null,
    overlayVisible: false as boolean,
    globalSettings: defaultGlobalOptions,
  }

  eventIdSeen: string[] = [];

  table: Table | null = null;

  private abortController = new AbortController();
  private abortController2 = new AbortController();

  constructor(props: Props) {
    super(props);

    this.state.playerId = (this.props.managedPlayerId ?? getQueryParam('player', '')) || null;

    try {
      this.state.globalSettings = JSON.parse(localStorage.getItem(this.props.uniqueViewId + '.globalSettings') ?? 'expecting error');
    } catch (error) {
      console.warn('Error parsing global settings:', error);
      this.state.globalSettings = defaultGlobalOptions;
    }
  }

  getCurrentTable() {
    if (this.table === null) {
      throw new Error('Table not loaded yet');
    }
    return this.table;
  }

  getCurrentScene() {
    return this.state.currentScene;
  }

  componentDidMount(): void {
    this.abortController = new AbortController();
    window.addEventListener('vchips_resizeContainer', this.onResize, { signal: this.abortController.signal });
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      this.onToggleSetting('theme', false);
    }, { signal: this.abortController2.signal });
    this.onToggleSetting('font', false, false);
    this.onToggleSetting('theme', false);
    this.onToggleSetting('sleep', false);
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }

  async onToggleSetting(key: string, next: boolean, emitResize = true) {
    const settings = { ...this.state.globalSettings };
    switch (key) {
      case 'font':
        settings.font = this.setFont(settings.font, next, emitResize);
        break;
      case 'theme':
        settings.theme = this.setTheme(settings.theme, next);
        break;
      case 'sleep':
        settings.sleep = await this.setSleep(settings.sleep, next);
        break;
    }
    this.setState({ globalSettings: settings });
    localStorage.setItem(this.props.uniqueViewId + '.globalSettings', JSON.stringify(settings));
  }

  setFont(v: string, next: boolean, emitResize = true): string {
    const root = this.rootContainerRef.current;
    if (!root) return v;

    const options = ['100%', '110%', '120%', '130%', '90%'];
    v = next ? options[(options.indexOf(v) + 1) % options.length] : v;

    if (root.style.fontSize === v) return v;

    root.style.fontSize = v;

    if (emitResize) {
      setTimeout(() => {
        document.dispatchEvent(new Event('vchips_animatedTextReload'));
        this.onResize();
      }, 500);
    }

    return v;
  }

  setTheme(v: string, next: boolean): string {
    const root = this.rootContainerRef.current;
    if (!root) return v;
    const options = ['auto', 'light', 'dark'];
    v = next ? options[(options.indexOf(v) + 1) % options.length] : v;

    let targetElem;
    if (this.props.managedPlayerId) targetElem = root;
    else targetElem = document.body;

    if (v === 'dark') targetElem.classList.add('darkM');
    if (v === 'light') targetElem.classList.remove('darkM');
    if (v === 'auto') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches)
        targetElem.classList.add('darkM');
      else
        targetElem.classList.remove('darkM');
    }

    return v;
  }

  private wakeLock: WakeLockSentinel | null = null;
  async setSleep(v: string, next: boolean): Promise<string> {
    const options = ['on', 'off'];
    v = next ? options[(options.indexOf(v) + 1) % options.length] : v;

    if (v === 'on') {
      // if (this.wakeLock && !this.wakeLock.released) return 'active'; // TODO
      if (this.wakeLock) this.wakeLock.release();
      try {
        this.wakeLock = await navigator.wakeLock.request("screen");
      } catch (err) {
        console.error('error encountered when trying to acquire wake lock', err);
        return 'error';
      }
    } else if (v === 'off') {
      if (this.wakeLock) this.wakeLock.release();
    }
    else console.error('Invalid sleep value:', v);

    return v;
  }

  setStateAsync = (state: any) => new Promise<void>((resolve) => this.setState(state, resolve));

  async getUndoRedoInfo() {
    return this.props.getUndoRedoInfo();
  }

  private onTableUpdate = async (newTable: Table) => {
    if (this.table?.toJSON() === newTable.toJSON()) {
      return;
    }

    const newSceneId = getSceneForTable(newTable);
    this.table = newTable;

    if (newSceneId !== this.state.currentScene) {
      await this.setOverlayVisibility(false);
      await this.useScene(newSceneId);
    } else {
      console.log('CentralManager: onTableUpdate');
      await this.sceneRef.current?.onTableUpdate(this.table);
    }
  }

  /** To be called from parent container */
  async onNewTableReceivedFromOthers(newTable: Table) {
    this.onTableUpdate(newTable);
  }

  /** To be called from children scenes */
  async triggerTableUpdate(newTable: Table, reason: string) {
    // Immediately update the table
    this.onTableUpdate(newTable);

    // Also send the event upstream
    this.props.updateOtherClients(newTable, reason);
  }

  async undoRedo(undo: boolean) {
    this.props.undoRedo(undo);
  }

  useScene = async (target: SceneId) => {
    await this.sceneRef.current?.sceneUnload();
    await this.setStateAsync({ currentScene: target });

    await this.sceneRef.current!.sceneLoad();
    await this.sceneRef.current!.sceneLayout(this.getSize(), true);
  }


  private onResize = async () => {
    console.log('CentralManager: onResize');
    await this.sceneRef.current?.sceneLayout(this.getSize(), true);
  }

  getSize(): Box {
    const elem = this.spritViewContainerRef.current;
    const root = this.rootContainerRef.current;
    if (!elem) {
      if (root) return new Box(root.scrollWidth / 2, root.scrollHeight / 2, root.scrollWidth / 2, root.scrollHeight / 2);
      return new Box(0, 0, 0, 0);
    }
    return new Box(elem.offsetLeft, elem.offsetTop, elem.offsetLeft + elem.scrollWidth, elem.offsetTop + elem.scrollHeight);
  }

  triggerResize = async () => {
    this.onResize();
  }

  async setCanvasVisibility(visible: boolean) {
    this.spritViewContainerRef.current!.style.display = visible ? 'block' : 'none';
  }

  async updatePlayerId(playerId: string) {
    await this.setStateAsync({ playerId });
  }

  async setOverlayVisibility(visible: boolean) {
    await this.setStateAsync({ overlayVisible: visible });
  }

  render() {
    const { uniqueViewId } = this.props;

    const GlobalButton = (props: { onClick: () => void }) =>
      <span className={`setting-button ${this.props.connected || 'red'}`} onClick={props.onClick}>
        {
          this.props.connected ?
            (
              this.props.connecting ?
                <Sync />
                :
                <Settings />
            )
            :
            (
              this.props.connecting ?
                <SyncProblemIcon />
                :
                <WarningAmberIcon />
            )
        }
      </span>

    return (
      <div className="central-manager w-full h-full relative flex flex-col" ref={this.rootContainerRef}>
        {
          this.spritViewRef.current && this.table &&
          <Wrapper
            Class={sceneLookupTable[this.state.currentScene!]}
            propsToClass={{
              cm: this,
              initialCanvas: this.getSize(),
              initialTable: this.table,
              playerId: this.state.playerId,
              spritView: this.spritViewRef.current,
              uniqueViewId: uniqueViewId,
              tableId: this.props.tableId,
              GlobalButton,
              globalSettings: {
                globalSettings: this.state.globalSettings,
                onChange: (key) => { this.onToggleSetting(key, true) },
                connected: this.props.connected,
                connecting: this.props.connecting,
                onDisconnect: this.props.disconnect,
                onReconnect: this.props.reconnect,
                cm: this,
              },
            }}
            sceneRef={this.sceneRef}
          />
        }
        <div id={idFor(uniqueViewId, "view-before")} />

        <div className="flex-1" ref={this.spritViewContainerRef} />
        <SpritView uniqueViewId={uniqueViewId} ref={this.spritViewRef} />

        <div id={idFor(uniqueViewId, "view-center")} className="contents" />

        <div className="pb-2" id={idFor(uniqueViewId, "view-after")} />

        <div className={`overlay ${this.state.overlayVisible || 'pointer-events-none'}`}>
          <motion.div className={`overlay-backdrop ${this.state.overlayVisible || 'opacity-0 pointer-events-none'}`} />
          <motion.div
            initial={false}
            animate={this.state.overlayVisible ? 'visible' : 'hidden'}
            transition={{ duration: 0.5, type: 'spring', bounce: 0.1 }}
            // negative opacity to prevent bouncing back and showing the overlay briefly when transitioning to hidden
            variants={{ visible: { opacity: 1, scale: 1 }, hidden: { opacity: -0.2, scale: 0.8 } }}
            className="overlay-gut"
            id={idFor(uniqueViewId, "overlay")}
          />
        </div>

      </div>
    );
  }
}

function Wrapper({ Class, propsToClass, sceneRef }: { Class: any, propsToClass: SceneProps, sceneRef: React.RefObject<SceneInterface> }) {
  if (!Class) return null;
  return <Class {...propsToClass} ref={sceneRef} />
}
