import { FC } from "react";
import { PokerCard } from "../../components";
import { GameRoundName } from "../../../models/types";
import { CommunityCards } from "../../components/PokerCard";


export const PokerCardsContainer2: FC<{ round: GameRoundName }> = ({ round }) => {
  const GameRoundNameToFaceUpCount: Record<GameRoundName, number> = {
    'pre-flop': 0,
    'flop': 3,
    'turn': 4,
    'river': 5,
    'showdown': 5,
  };

  const faceUpCount = GameRoundNameToFaceUpCount[round];

  return (
    <div className="flex flex-row items-center justify-center text-xs relative">
      <div className='opacity-50 absolute -top-5 left-0 h-full w-full text-center text-sm z-10' style={{ 'fontVariant': 'petite-caps' }}>
        {round}
      </div>
      <CommunityCards cards={Array.from({ length: faceUpCount }).map(_ => PokerCard.blank)} blank={true} />
    </div>
  );
}
