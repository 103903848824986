import { Button } from "@mui/material";
import PokerCard, { CardContainer, CommunityCards, PlayerCards } from "./components/PokerCard";
import React from "react";

export default function TestPage() {
  const c = [
    { suit: PokerCard.Suit.heart, rank: PokerCard.Rank.$A },
    { suit: PokerCard.Suit.club, rank: PokerCard.Rank.$10 },
    { suit: PokerCard.Suit.diamond, rank: PokerCard.Rank.$J },
    { suit: PokerCard.Suit.heart, rank: PokerCard.Rank.$Q },
    { suit: PokerCard.Suit.spade, rank: PokerCard.Rank.$K },
  ];

  const [nToShow, setN] = React.useState(0);
  // const c = [PokerCard.blank, PokerCard.blank, PokerCard.blank, PokerCard.blank, PokerCard.blank];
  return (
    <div className="grid cols-1 gap-2 p-3">

      <div className="flex flex-wrap gap-1">
        {
          PokerCard.ranks.map(rank =>
            PokerCard.suits.map(suit =>
              <PokerCard key={rank + suit} card={{ rank, suit }} />
            )
          )
        }
      </div>

      <PlayerCards state="none" cardA={{ suit: PokerCard.Suit.club, rank: PokerCard.Rank.$A }} cardB={{ suit: PokerCard.Suit.heart, rank: PokerCard.Rank.$K }} />
      <PlayerCards state="visible" cardA={{ suit: PokerCard.Suit.club, rank: PokerCard.Rank.$A }} cardB={{ suit: PokerCard.Suit.heart, rank: PokerCard.Rank.$K }} />
      <PlayerCards state="hidden" cardA={{ suit: PokerCard.Suit.club, rank: PokerCard.Rank.$A }} cardB={{ suit: PokerCard.Suit.heart, rank: PokerCard.Rank.$K }} />

      <CardContainer>
        <PokerCard.Facedown />
        <PokerCard.Facedown />
        <PokerCard.Facedown />
      </CardContainer>

      <Button variant="contained" color="primary" onClick={() => setN(x => x + 1)}>+</Button>
      <Button variant="contained" color="primary" onClick={() => setN(x => x - 1)}>-</Button>
      <CommunityCards cards={c.slice(0, nToShow)} />
    </div>
  )

}
