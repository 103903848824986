import React from "react";
import { PLAYER_SIZE, portalForIf } from "../types";

import { Table } from "../../../models/poker";
import { ChipAmount, Sprit } from "../../components";
import PlayerCard from "../PlayerCard";

import { Box, Vector } from '@flatten-js/core';
import { ChipAmount as ChipAmountType, PlayerAction, PlayerActionOptions } from "../../../models/types";
import { arrayReorder } from "../../../models/helpers";
import Assert from "../../../models/assert";


import { SceneProps, SceneInterface } from '../types'
import { SpritView } from "../SpritView";
import { layout } from "../ATable/TableView";
import { GlobalSettings } from "../GlobalSettings";
import { CentralManager } from "../CentralManager";
import { BetCtrlCombo } from "./BetCtrlWrapper";
import { PokerCardsContainer2 } from "./PokerCards";
import { PokerRulesOverlay } from "../PokerRulesOverlay";

interface State {
  chipOverride: { player: string, amount: ChipAmountType } | null,
  sceneLoaded: boolean,
  overlay: 'global' | 'poker-rules' | null,
}

async function layoutPlayer(canvas: Box, players: Sprit[], smooth: boolean, emptySelf: boolean) {
  return await layout('player', players, canvas, smooth, emptySelf);
}


async function focusOnPlayerHelper(cpf: Sprit, player: Sprit | null, smooth: boolean) {
  if (player === null) {
    await cpf.toVariant(smooth, 'hidden');
    return;
  }

  const wasHidden = cpf.currentVariant === 'hidden';
  if (wasHidden) {
    cpf.cAt(player.box.center);
  }

  cpf.zIndex = 1;
  cpf.MVs.borderColor.set('var(--color-black-or-white)');
  await Promise.all([
    wasHidden || cpf.animateC(player.box.center, 'spring'),
    cpf.animateToVariant('visible'),
  ])
}


export class GameView extends React.Component<SceneProps, State> implements SceneInterface {
  canvas: Box;
  table: Table;
  cm: CentralManager;
  sv: SpritView;

  setStateAsync = (state: any) => new Promise<void>((resolve) => this.setState(state, resolve));

  state: State = {
    sceneLoaded: false,
    chipOverride: null,
    overlay: null,
  }

  get g() {
    Assert.expect(this.table.currentGame).beTruthy();
    return this.table.currentGame!;
  }

  constructor(props: SceneProps) {
    super(props);
    this.canvas = props.initialCanvas;
    this.table = props.initialTable;
    this.cm = props.cm;
    this.sv = props.spritView;
  }

  pot!: Sprit;
  ctp!: Sprit;
  cpf!: Sprit;
  players: Sprit[] = [];

  get emptySelf() {
    if (!this.props.playerId) return true;
    if (!this.g.allPlayers.includes(this.props.playerId)) return true;
    return false;
  }

  sceneLoad = async () => {

    let firstPlayerInLayout = this.g.allPlayers[0];
    if (this.props.playerId && this.g.allPlayers.includes(this.props.playerId)) {
      firstPlayerInLayout = this.props.playerId;
    }

    this.sv.batchStart();
    this.players = arrayReorder(this.g.allPlayers, firstPlayerInLayout).map(p => {
      return this.sv.findOrCreate(
        { player: p },
        {
          size: PLAYER_SIZE,
          ifNew: sprit => { sprit.cAt(this.canvas.center) },
        }
      );
    });


    this.pot = this.sv.findOrCreate(
      { name: 'pot' },
      {
        size: { x: 250, y: 40 },
        ifNew: sprit => { sprit.cAt(this.canvas.center) },
        // ifOld: sprit => sprit.animateC(this.canvas.center),
      }
    );


    this.ctp = this.sv.findOrCreate(
      { name: 'chip-to-pot' },
      {
        size: { x: 50, y: 25 },
        c: this.canvas.center,
        variants: {
          'hidden': { scale: 0, opacity: 0 },
          'visible': { scale: 1, opacity: 1 },
        },
        initialVariant: 'hidden',
      }
    );

    this.cpf = this.sv.findOrCreate(
      { name: 'current-player-focus' },
      {
        size: PLAYER_SIZE,
        variants: {
          'hidden': { scale: 1.2, opacity: 0, borderWidth: 0 },
          'visible': { scale: 1, opacity: 1, borderWidth: 5 },
        },
        initialVariant: 'hidden',
      }
    );

    await this.sv.batchEnd();
    await this.setStateAsync({ sceneLoaded: true });
  };

  sceneLayout = async (canvas: Box, smooth: boolean) => {
    this.canvas = canvas;
    await this.setStateAsync({ chipOverride: null });
    await Promise.all([
      this.pot.animateC(this.canvas.center),
      layoutPlayer(this.canvas, this.players, smooth, this.emptySelf),
    ])
    await this.focusOnPlayer(this.g.currentPlayer, smooth);
  }

  sceneUnload = async () => {
    await this.focusOnPlayer(null, true);
    await Promise.all([
      this.sv.remove({ name: 'pot' }),
      this.sv.remove({ name: 'chip-to-pot' }),
      this.sv.remove({ name: 'current-player-focus' }),
    ]);
    await this.setStateAsync({ sceneLoaded: false });
  };

  focusOnPlayer = async (player: string | null, smooth = true) => {
    await focusOnPlayerHelper(this.cpf, player === null ? null : this.sv.get({ player }), smooth);
  }

  onTableUpdate = async (table: Table) => {
    await this.currentAnimation;
    this.table = table;
    await this.sceneLayout(this.canvas, true);
  }

  private currentAnimation = Promise.resolve();
  onPlayerAction = async (action: PlayerAction) => {

    this.currentAnimation = (async () => {
      const player = this.g.currentPlayer!;
      const amount = (action.type === 'raise') ? action.raisingAmount + this.g.currentPlayerActionOptions.raise!.baseAmount
        :
        (action.type === 'call') ? this.g.currentPlayerActionOptions.call!.amount
          :
          0;

      this.setStateAsync({ chipOverride: { player, amount } });

      if (amount > 0) {
        this.ctp.cAt(this.sv.get({ player }).box.center.translate(new Vector(0, -this.sv.get({ player }).box.height / 2 - this.ctp.box.height / 2)));
        await this.ctp.animateToVariant('visible');
        await new Promise(resolve => setTimeout(resolve, 500));
        await this.ctp.animateC(this.sv.get({ name: 'pot' }).box.center.translate(new Vector(0, 40)), 'easeInOut'); // hard-coded value
        await new Promise(resolve => setTimeout(resolve, 500));
        await this.ctp.animateToVariant('hidden');
      } else {
        //
      }


      await this.focusOnPlayer(this.g.nextPlayer(action));
      await new Promise(resolve => setTimeout(resolve, 500));

    })();

    const text = `${this.g.currentPlayer} ${action.type}s`;
    const newT = this.table.copy();
    newT.currentGame!.act(action);
    /* Not awaiting here */ this.cm.triggerTableUpdate(newT, text);
  }

  showOverlay = async (view: State['overlay']) => {
    if (view) {
      await this.setStateAsync({ overlay: view });
      await this.cm.setOverlayVisibility(true);
    } else {
      await this.cm.setOverlayVisibility(false);
    }
  }


  render() {
    const { GlobalButton } = this.props;
    const portalFor = portalForIf(this.props.uniqueViewId, this.state.sceneLoaded);

    return [
      portalFor('view-before')(
        <div className="view-before">
          <div className="text-and-settings">
            <GlobalButton onClick={() => this.showOverlay('global')} />
            <div className="prompt-text">
              {actionOptionsToText(this.g.currentPlayer!, this.g.currentPlayer === this.props.playerId, this.g.currentPlayerActionOptions)}
            </div>
          </div>
        </div>
      )
      ,
      portalFor('view-after')(
        <div className="px-2 pt-1.5">
          {
            this.cm.props.singlePhoneMode ?
              <div className="mx-auto w-full text-center py-1"><span className="font-bold" style={{ 'fontVariant': 'petite-caps' }}>acting for</span> <span className="player">{this.g.currentPlayer}</span></div>
              : null
          }
          <BetCtrlCombo
            game={this.g}
            act={this.onPlayerAction}
            playerId={this.cm.props.singlePhoneMode ? (this.g.currentPlayer ?? undefined) : (this.props.playerId ?? undefined)}
          />
        </div>
      )
      ,
      portalFor(this.ctp)(
        <ChipAmount doNotAnimate amount={this.state.chipOverride?.amount ?? 0} />
      )
      ,
      portalFor(this.pot)(
        <div className="flex flex-col items-center justify-center">
          <PokerCardsContainer2 round={this.g.currentRound} />
          <div className="__border rounded py-1 px-2 mt-1">
            <span className="font-bold" style={{ 'fontVariant': 'petite-caps' }}>pot</span> <ChipAmount amount={(this.g.totalPotAmountPlusCurrentBets ?? 0) + (this.state.chipOverride?.amount ?? 0)} />
          </div>
        </div>
      )
      ,
      ...this.g.allPlayers.map(player =>
        portalFor({ player })(
          <PlayerCard
            game={this.g}
            player={player}
            chipAmountOverride={this.state.chipOverride?.player === player ? -this.state.chipOverride.amount : undefined}
          />
        )
      )
      ,
      portalFor('overlay')(
        <>
          {
            this.state.overlay === 'global' &&
            <GlobalSettings sharedOptions={this.props.globalSettings} close={() => this.showOverlay(null)} tmpShowPoker={() => this.showOverlay('poker-rules')} />
          }

          {
            this.state.overlay === 'poker-rules' &&
            <PokerRulesOverlay close={() => this.showOverlay(null)} />
          }
        </>
      )
    ];
  }
}


/**
 *
 * @param playerId
 * @param isMe
 * @param actionOptions
 * @returns string
 *
 * It's Dennis's turn: 50 to call
 * It's Dennis's turn: all-in (50) to call
 * It's Dennis's turn
 * It's your turn: 50 to call
 * It's your turn: all-in (50) to call
 * It's your turn
 */
function actionOptionsToText(playerId: string, isMe: boolean, actionOptions: PlayerActionOptions) {
  const textPart1 = isMe ? 'your turn' : <><span className="player">{playerId}</span>'s turn</>;
  let textPart2 = '';
  if (actionOptions.call) {
    if (actionOptions.call.isAllIn) {
      textPart2 = `: all-in (${actionOptions.call.amount}) to call`;
    } else {
      textPart2 = `: ${actionOptions.call.amount} to call`;
    }
  }

  return <>It's {textPart1}{textPart2}</>;
}
