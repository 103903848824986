import { ChipAmount } from "../components";
import { Game, Table } from "../../models/poker";
import { AnimatedText } from "../components/AnimatedText";
import { findPlayer } from "../../models/helpers";
import PokerCard, { PlayerCards } from "../components/PokerCard";

export default function PlayerCard(props: { game: Game | null, player: string, chipAmountOverride?: number }) {
  if (!props.game)
    return (
      <div className={`h-full w-full flex flex-col items-center justify-center text-center`}>
        <div className={`player player-card-player-font-size`}>{props.player}</div>
      </div>
    )

  const g = props.game;
  const player = props.player;
  const isAllIn = g.allInPlayers.includes(player);
  const isFolded = g.foldedPlayers.includes(player);

  const colors = {
    blue: 'bg-blue-500',
    green: 'bg-green-500',
    red: 'bg-red-500',
    gray: 'bg-gray-500',
  }

  let prevActionText = previousActionText(g, player);
  let nextActionText = availableActionText(g, player);

  if (prevActionText?.includes('blind')) {
    if (nextActionText?.includes('option') || nextActionText?.includes(g.settings.smallBlindAmount + ' to call')) {
      nextActionText = prevActionText;
      prevActionText = null;
    }
  }

  return (
    <div className={`h-full w-full flex flex-col items-center justify-center text-center ${isFolded && 'text-gray-500 text-opacity-60'}`}>
      <div>
        {
          !isAllIn ? null :
            <div className={`px-2 transition-all ${colors.red} bg-opacity-30 rounded-lg opacity-80 player-card-action-text-size`}>
              {prevActionText} {'(all-in)'.split('').join('\uFEFF')}
            </div>
        }
        {
          !isFolded ? null :
            <div className={`px-2 transition-all ${colors.gray} bg-opacity-30 rounded-lg opacity-80 player-card-action-text-size`}>
              {prevActionText}
            </div>
        }
        {
          !(prevActionText && !isAllIn && !isFolded && !nextActionText) ? null :
            <div className={`px-2 transition-all ${nextActionText ? colors.gray : colors.green} bg-opacity-30 rounded-lg opacity-80 player-card-action-text-size`}>
              {prevActionText}
            </div>
        }
        {
          !nextActionText ? null :
            <div className={`px-2 transition-all ${colors.blue} bg-opacity-30 rounded-lg opacity-80 player-card-action-text-size`}>
              {nextActionText}
            </div>
        }
      </div>

      <div className={`player player-card-player-font-size`}>{props.player}</div>

      <div>
        <ChipAmount amount={(props.chipAmountOverride ?? 0) + g.gameStacks[player]} />
      </div>

      {/*
      // Hide player cards for now
      <div className="absolute -z-10 opacity-5 bottom-3">
        <PlayerCards state={isFolded ? "none" : "hidden"} cardA={PokerCard.blank} cardB={PokerCard.blank} />
      </div>
      */}
    </div>
  );
}

export function PlayerCardForSetup(props: { table: Table, player: string }) {
  const t = props.table;
  const player = props.player;

  const hasZeroChips = t.tableStacks[player] === 0;


  const positionText = [
    t.buttonPlayer && findPlayer(t.players, t.buttonPlayer, 'bb') === player && 'Big Blind',
    t.buttonPlayer && findPlayer(t.players, t.buttonPlayer, 'sb') === player && 'Small Blind',
    t.buttonPlayer === player && 'Dealer',
  ].filter(Boolean).join(', ');

  return (
    <div className={`h-full w-full flex flex-col items-center justify-center text-center ${hasZeroChips && 'text-gray-500 text-opacity-60'}`}>
      <div className={`px-2 bg-gray-500 bg-opacity-30 rounded-lg opacity-80 player-card-action-text-size`}>
        {positionText}
      </div>

      <div className={`player player-card-player-font-size`}>{props.player}</div>

      <div>
        <ChipAmount amount={t.tableStacks[player]} />
      </div>
    </div>
  );
}

export function PlayerCardForShowdown(props: { player: string, winner: boolean, chipsGain?: number }) {
  return (
    <div className={`h-full w-full flex flex-col items-center justify-center text-center transition-all`}>
      {
        props.winner ?
          <div className={`px-2 bg-green-500 bg-opacity-30 rounded-lg opacity-80 player-card-action-text-size `}>
            winner
          </div>
          :
          <div className={`px-2 player-card-action-text-size`}>
            &nbsp;
          </div>
      }

      <div className={`player player-card-player-font-size`}>{props.player}</div>

      {props.chipsGain !== undefined &&
        <div className="">+<ChipAmount doNotAnimate amount={props.chipsGain} /></div>
      }
    </div>
  );
}

/**
 * Example output:
 * 0. (empty) if folded
 * 1. Option
 * 2. 50 to call
 * 3. All-in to call
 */
function availableActionText(g: Game, player: string): string | null {
  if (!g.allPlayers.includes(player)) return 'sit-out';
  if (g.currentRound === 'showdown') return 'showdown';

  if (g.actQueue.includes(player)) {
    const maxBetSoFar = Math.max(...Object.values(g.currentBets), 0);
    const toCall = maxBetSoFar - (g.currentBets[player] ?? 0);
    if (toCall === 0) return 'option';
    if (toCall >= g.gameStacks[player]) return 'all-in to call';
    return `${toCall} to call`;
  }

  return null;
}

/**
 * Example output:
 * 1. Folded
 * 2. Called 50 (all-in)
 * 3. Raised to 100 (all-in)
 * 4. Checked
 * 5. Bet 50 (all-in)
 */
function previousActionText(g: Game, player: string): string | null {
  if (!g.allPlayers.includes(player)) return 'sit-out';
  if (g.currentRound === 'showdown') return 'showdown';

  const currentRoundActionHistory = g.actionHistory[g.currentRound] ?? [];
  const lastActionIndex = currentRoundActionHistory.findLastIndex(history => history.player === player);
  if (lastActionIndex === -1) return null;

  const action = currentRoundActionHistory[lastActionIndex].action;
  const actionOption = currentRoundActionHistory[lastActionIndex].actionOptions;

  if (action.type === 'fold') return null; // handled separately
  if (action.type === 'blinds') return action.sbBb === 'sb' ? ('small blind (' + g.settings.smallBlindAmount + ')') : ('big blind (' + g.settings.bigBlindAmount + ')');
  if (action.type === 'check') return 'checked';
  if (action.type === 'call') return `called ${actionOption.call?.amount}`;
  if (action.type === 'raise') {
    const totalRaisesSoFar = currentRoundActionHistory.filter((history, i) => i < lastActionIndex && history.action.type === 'raise').length;

    if (totalRaisesSoFar === 0)
      return `bet ${action.raisingAmount}`;
    else if (totalRaisesSoFar === 1)
      return `raised ${action.raisingAmount}`;
    else if (totalRaisesSoFar === 2)
      return `re-raised ${action.raisingAmount}`;
    else
      return `${totalRaisesSoFar}-raised ${action.raisingAmount}`;
  }

  return 'error';
}
