import React from 'react'

import { Table } from '../../models/poker'
import { TableId } from '../../models/types'

import { ServerProxyFirebase, ServerProxyLocal, ServerProxyInterface } from '../../models/server'
import { CentralManager } from '../view/CentralManager'

interface Props {
  managedPlayerId?: string;
  tableId: TableId;
  onTableDNE: () => void;
  useServer: 'remote' | 'local';
}

interface State {
  connecting: boolean;
  isConnected: boolean;
}

export function getQueryParam(name: string, defaultValue: string): string {
  return new URLSearchParams(window.location.search).get(name) ?? defaultValue;
}

export default class ClientController extends React.Component<Props, State> {
  get isManaged() {
    return this.props.managedPlayerId !== undefined;
  }

  state: State = {
    connecting: false,
    isConnected: false,
  }

  cm = React.createRef<CentralManager>();
  proxy!: ServerProxyInterface;
  tryConnectInterval: ReturnType<typeof setTimeout> | null = null;

  componentDidMount(): void {
    if (!this.proxy) {
      if (this.props.useServer === 'remote') {
        this.proxy = new ServerProxyFirebase();
        (this.proxy as ServerProxyFirebase).playerId = this.props.managedPlayerId ?? getQueryParam('playerId', '');
      } else {
        this.proxy = new ServerProxyLocal();
        new ServerProxyLocal().createTableIfNotExists(this.props.tableId);
      }
      this.proxy.registerHandlers({
        onConnect: this.onServerUpdate,
        onDisconnect: this.onServerDisconnected,
        onUpdate: this.onServerUpdate,
        onTableDNE: this.onServerTableDNE,
      });
      this.connect();
    }
  }

  connect = () => {
    this.setState({ connecting: true });
    this.proxy.joinTable(this.props.tableId);
    if (!this.isManaged) {
      document.title = `Connecting to table ${this.props.tableId} | vchips.app`
    }
  }

  onServerDisconnected = () => {
    this.setState({ isConnected: false, connecting: false });
    if (!this.isManaged) {
      document.title = `DISCONNECTED from table ${this.props.tableId} | vchips.app`
    }
    if (this.tryConnectInterval) clearTimeout(this.tryConnectInterval);
    this.tryConnectInterval = setTimeout(() => {
      this.setState({ connecting: true });
      this.proxy.exitTable();
      this.proxy.joinTable(this.props.tableId);
    }, 2000);
  }

  onServerUpdate = (table: Table) => {
    if (this.tryConnectInterval) clearTimeout(this.tryConnectInterval);
    if (!this.isManaged) document.title = `Table ${this.props.tableId} | vchips.app`;
    this.setState({ isConnected: true, connecting: false });
    this.cm.current?.onNewTableReceivedFromOthers(table);
  }

  updateOtherClients = (table: Table, reason: string) => {
    this.proxy.updateTable(table, reason);
  }

  getUndoRedoInfo = async () => {
    return await this.proxy.getUndoRedoInfo();
  }

  onServerTableDNE = () => {
    this.props.onTableDNE();
  }

  disconnect = () => {
    this.proxy.exitTable();
    this.setState({ isConnected: false, connecting: false });
  }

  render() {
    return (
      <CentralManager
        ref={this.cm}
        uniqueViewId={this.props.managedPlayerId ? (this.props.tableId + this.props.managedPlayerId) : ''}
        updateOtherClients={this.updateOtherClients}
        undoRedo={(b: boolean) => { this.proxy.undoRedo(b) }}
        managedPlayerId={this.props.managedPlayerId}
        tableId={this.props.tableId}
        connected={this.state.isConnected}
        connecting={this.state.connecting}
        disconnect={this.disconnect}
        reconnect={this.connect}
        getUndoRedoInfo={() => this.getUndoRedoInfo()}
        singlePhoneMode={!!getQueryParam('singlePhoneMode', '')}
      />
    )
  }
}


