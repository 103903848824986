import React, { useCallback } from 'react';
import { Game } from "../../../models/poker";
import { PlayerAction, PlayerActionOptions } from '../../../models/types';
import { Add, Remove } from '@mui/icons-material';
import './BettingControlC.css';

interface Props {
  game: Game,
  act: (action: PlayerAction) => void,
  myStack: number,
  actions: PlayerActionOptions | null,
};

const BIG = 9999999999;

const BettingControlB: React.FunctionComponent<Props> = ({ game, act: act_, myStack, actions }) => {
  const bb = game.settings.bigBlindAmount;

  const [didAct, setDidAct] = React.useState(false);

  const act = useCallback((action: PlayerAction) => {
    act_(action);
    // setInputValue(0);
    setDidAct(true);
  }, [act_]);

  React.useEffect(() => {
    setInputValue(0);
    setDidAct(false);
  }, [game]);

  const defaultAmount = useCallback(() => {
    if (!actions) return 0;
    if (actions.check) return 0;
    return actions.call!.amount;
  }, [actions]);

  const previousDefaultAmount = React.useRef<any>(null);
  const [inputValue, setInputValue] = React.useState(defaultAmount);


  const inputValueMin = !actions ? 0 : actions.check ? 0 : actions.call!.amount;
  const inputValueMax = !actions ? myStack : actions.raise ? (actions.raise.maxAmount + actions.raise.baseAmount) : Math.min(actions.call?.amount ?? BIG, myStack);


  const adjustChipSelection = useCallback((change: number) => {
    setInputValue(inputValue => {
      if (isNaN(inputValue)) return defaultAmount();

      let newValue = inputValue + change;

      if (newValue <= inputValueMin) return inputValueMin;
      if (newValue >= inputValueMax) return inputValueMax;

      // If actions.raise is defined, and the value is in the invalid range, then snap to the nearest valid value
      if (actions?.raise && actions.raise.baseAmount < newValue && newValue < actions.raise.baseAmount + actions.raise.minAmount) {
        if (change > 0) newValue = actions.raise.baseAmount + actions.raise.minAmount;
        else newValue = actions.raise.baseAmount;
      }

      return newValue;
    });
  }, [defaultAmount, inputValueMin, inputValueMax, actions]);


  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  // Run this logic when inputValue has changed
  React.useEffect(() => {
    const didDefaultAmountChange = previousDefaultAmount.current !== defaultAmount();
    previousDefaultAmount.current = defaultAmount();

    // Cancel previously scheduled timeout
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    // If default amount changed (the only reason should be it's our turn now)...
    if (didDefaultAmountChange)
      // Set inputValue to defaultAmount immediately, which is the lowest possible number
      timeoutRef.current = setTimeout(() => adjustChipSelection(-BIG), 0);
    else
      // Otherwise, we wait some time for user to finish typing, then validate the input
      timeoutRef.current = setTimeout(() => adjustChipSelection(0), 1200);

  }, [inputValue, defaultAmount, adjustChipSelection]);


  const currentInputIsValid = (
    // must be within min and max
    inputValueMin <= inputValue && inputValue <= inputValueMax &&
    (
      // if actions raise is defined, then enforce min-raise - must not be between baseAmount and baseAmount + minAmount
      actions?.raise ?
        !(actions?.raise?.baseAmount < inputValue && inputValue < actions?.raise?.baseAmount + actions?.raise?.minAmount)
        :
        true
    )
  );

  let currentAction: 'call' | 'raise' | 'check' | null = null;
  let currentActionAmount: number | null = null;

  currentAction = inputValue !== inputValueMin ? 'raise' : inputValue === 0 ? 'check' : 'call';
  currentActionAmount = inputValue;

  if (!actions) currentAction = null;

  let currentActionProcessedForDisplay: string = currentAction ?? '(not your turn)';
  // If player wants to "raise" while they can check, use the word "bet" instead
  if (currentActionProcessedForDisplay === 'raise' && actions?.check)
    currentActionProcessedForDisplay = 'bet';
  if (!currentInputIsValid)
    currentActionProcessedForDisplay = '(invalid)';
  if (didAct)
    currentActionProcessedForDisplay = '(submitting...)';

  const shouldShowFold = inputValue === defaultAmount() && currentAction;

  return (
    <div className="grid primary-game-display-grid-area3 gap-2">
      <div className="sub btn" onClick={() => adjustChipSelection(-bb)}><Remove /></div>
      <div className="input">
        <input type="text" inputMode="numeric" value={inputValue} onChange={(e) => setInputValue(Number(e.target.value))} onFocus={(e) => { e.target.select() }} />
      </div>
      <div className="add btn" onClick={() => adjustChipSelection(+bb)}><Add /></div>
      {/********** Action Button **********/}
      <div className={`action btn ${(!currentAction || !currentInputIsValid || didAct) && 'invalid-state'}`} onClick={() => {
        if (!currentAction) return;
        if (currentAction === 'check') act({ type: 'check' });
        if (currentAction === 'call') act({ type: 'call' });
        if (currentAction === 'raise') act({ type: 'raise', raisingAmount: currentActionAmount! - actions!.raise!.baseAmount! });
      }}>
        {/* This this the verb - check, bet, raise, etc */}
        {currentActionProcessedForDisplay}
        {/* This is the suffix. The amount and weather it's all in */}
        {
          currentAction && currentInputIsValid && !didAct && (currentActionAmount || null) && (
            <>
              <span className="amount">
                {' '}
                {
                  currentAction === 'raise' ?
                    currentActionAmount - (actions?.raise?.baseAmount ?? BIG)
                    :
                    currentActionAmount
                }
              </span>
              <span>{currentActionAmount === inputValueMax ? <><br />(all-in)</> : ''}</span>
            </>
          )
        }
      </div>
      <div className="sep"></div>
      <div
        className={`col-span-2 btn min ${shouldShowFold && 'red'}`}
        onClick={() => shouldShowFold ? act({ type: 'fold' }) : adjustChipSelection(-BIG)}
      >
        {shouldShowFold ? 'fold' : 'clear'}
      </div>
      <div className="col-span-2 btn max" onClick={() => adjustChipSelection(BIG)}>max</div>

    </div>

  );
};

export default BettingControlB;
