import { ReactNode } from 'react'

interface Props {
  children: ReactNode,
  height?: number,
  width?: number,
}

export default function DisplayContainer({ children, height, width }: Props) {
  return (
    <div className={`display-container w-full h-full ${height && 'border-4 border-yellow-500'}`} style={{ height, width }}>
      {children}
    </div>
  )
}
