import { Table } from "../../models/poker";
import { Box } from '@flatten-js/core';
import { SpritId, SpritView } from "./SpritView";
import { createPortal } from "react-dom";
import { Sprit } from "../components";
import React from "react";
import { SharedOptions } from "./GlobalSettings";
import { CentralManager } from "./CentralManager";


export interface SceneProps {
  initialCanvas: Box;
  initialTable: Table;
  playerId: string | null;
  tableId: string;
  cm: CentralManager;
  spritView: SpritView;
  uniqueViewId: string;
  GlobalButton: React.FC<{ onClick: () => void }>;
  globalSettings: SharedOptions;
}

export type SceneId = 'game' | 'table' | 'showdown-simple' | 'showdown-complex' | 'result';

export interface SceneInterface {
  cm: CentralManager;

  sceneLoad: () => Promise<void>;
  sceneLayout: (canvas: Box, smooth: boolean) => Promise<void>;
  sceneUnload: () => Promise<void>;

  onTableUpdate: (table: Table) => Promise<void>;
}


export const PLAYER_SIZE = { x: 120, y: 100 }


export function portalForIf(uniqueViewId: string, loaded: boolean) {
  return (sprit: Sprit | SpritId | string) => {
    return (node: React.ReactNode) => {
      if (!sprit) return null;
      const elementId = idFor(uniqueViewId, sprit);
      const elem = document.getElementById(elementId);

      const isStringId = typeof sprit === 'string';
      const exists = elem !== null;

      if (loaded || isStringId) {
        if (!exists) {
          console.error('Element not found for', elementId);
          return null;
        }
        return createPortal(node, elem, elementId);
      } else {
        // not loaded sprits
        if (exists) {
          return createPortal(node, elem, elementId);
        } else {
          // console.log(`Sprit not loaded yet: ${elementId}`);
          return null;
        }
      }
    }
  };
}

export function idFor(uniqueViewId: string, spritOrSpritIdOrString: Sprit | SpritId | string) {
  let r;
  if (spritOrSpritIdOrString instanceof Sprit) {
    r = JSON.stringify(spritOrSpritIdOrString.id) + uniqueViewId;
  }

  else if (spritOrSpritIdOrString instanceof Object) {
    r = JSON.stringify(spritOrSpritIdOrString) + uniqueViewId;
  }

  else {
    r = spritOrSpritIdOrString + uniqueViewId;
  }

  r = r.replace(/["{}]/g, '').replace(/[^-a-zA-Z0-9._]/g, '_');
  return r;
}
