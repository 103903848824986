import Toll from '@mui/icons-material/Toll';
import { AnimatedText } from './AnimatedText';

interface Props {
  amount: number;
  doNotAnimate?: boolean;
}

export default function ChipAmount({ amount, doNotAnimate }: Props) {
  return (
    <div className={`inline-flex w-fit items-center`}>
      <div className="scale-90 mr-0.5"><Toll fontSize="inherit" /></div>
      <div className="amount">
        {
          doNotAnimate ?
            <div>{String(amount)}</div>
            :
            <AnimatedText duration={1.0}>{String(amount)}</AnimatedText>
        }
      </div>
    </div>
  )
}
