import { Game } from "../../../models/poker";
import { PlayerAction } from "../../../models/types";
import BettingControlC from "./BettingControlC";


export function BetCtrlCombo(props: { game: Game, act: (p: PlayerAction) => void, playerId?: string }) {
  const { game, act, playerId } = props;

  if (game.currentRound === 'showdown') return null;
  return (
    <BettingControlC game={game} actions={game.currentPlayer === playerId ? game.currentPlayerActionOptions : null} act={act} myStack={game.gameStacks[playerId ?? 'NOT EXISTENT PLAYER']} />
  )
}
