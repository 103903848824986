import React from "react";
import { CentralManager } from "./CentralManager";
import { PokerCard } from "../components";
import { Card, CardContainer, PlayerCards } from "../components/PokerCard";

export interface GlobalOptions {
  font: string,
  theme: string,
  sleep: string,
}

export interface SharedOptions {
  connected: boolean;
  connecting: boolean;
  onDisconnect: () => void;
  onReconnect: () => void;
  cm: CentralManager;
  globalSettings: GlobalOptions;
  onChange: (key: string) => void,
}

export const PokerRulesOverlay = (props: { close: () => void }) => {
  const ranks = pokerRanks();
  return (
    <>
      <div className="overlay-common">
        <h1 className="text-center text-xl font-bold mt-4 mb-6 overflow-auto">Poker Hand Rankings</h1>
        <div className="relative text-center grid poker-grid gap-x-4 gap-y-2 justify-center items-center bg-purple-500 bg-opacity-5 py-4 -mx-2 px-2">
          <span className="absolute -right-5 top-10 -rotate-90">better »»</span>
          <span className="absolute -right-5 bottom-10 -rotate-90">«« worse</span>
          {
            Object.entries(ranks).map(([name, cards], i) => (
              <React.Fragment key={name}>
                <div className="justify-self-end">
                  <CardContainer>
                    {cards.map((card, i) => <span className={card.showDarken ? 'opacity-30' : 'opacity-100'}><PokerCard key={i} card={card as unknown as Card} /></span>)}
                  </CardContainer>
                </div>
                <span className="text-lg text-left">{name}</span>
              </React.Fragment>
            ))
          }
        </div>
        <h1 className="text-center text-xl font-bold mt-8 mb-6 overflow-auto">Poker Rule Quickstart</h1>
        <div className="text-left px-4">
          <p className="mt-3">Each player gets <span className="font-bold">2 private cards</span>.</p>
          <div className="w-fit m-auto"><PlayerCards state="hidden" cardA={PokerCard.blank} cardB={PokerCard.blank} /></div>
          <p className="mt-3">There will be <span className="font-bold">5 community cards</span> on the table.</p>
          <div className="w-fit m-auto"><CardContainer>
            <PokerCard.Blank /><PokerCard.Blank /><PokerCard.Blank /><PokerCard.Blank /><PokerCard.Blank />
          </CardContainer></div>
          <p className="mt-3">You create your best <span className="font-bold">5-card hand</span> by combining your hole cards and the community cards.</p>
          <p className="mt-3">Hands are compared by <span className="font-bold">rank</span> first (e.g., Flush beats Straight), and then by the <span className="font-bold">individual cards</span> if needed (e.g., higher pair wins).</p>
          <p className="mt-3">In case of a tie, the <span className="font-bold">pot is split</span> evenly among the winners.</p>
          <p className="mt-3">&nbsp;</p>
        </div>
      </div>
      <div className="button3 w-full" onClick={() => props.close()}>Close</div>
    </>
  )
}

function pokerRanks() {
  function fromStringToCard(cards: string) {
    return cards.split('|')[0].split('/').flatMap((card) => {
      if (card == '') return [];
      const [rank, suit] = [card[0], card[1]];
      return { rank, suit, showDarken: false };
    }).concat(
      cards.split('|')[1].split('/').flatMap((card) => {
        if (card == '') return [];
        const [rank, suit] = [card[0], card[1]];
        return { rank, suit, showDarken: true };
      }));
  }

  // return {
  //   'Straight flush': fromStringToCard('9♠︎/8♠︎/7♠︎/6♠︎/5♠︎|'),
  //   'Four of a kind': fromStringToCard('A /A /A_/A_|  '),
  //   'Full house': fromStringToCard('A /A /A_/2 /2_|'),
  //   'Flush': fromStringToCard(' ♦/ ♦/ ♦/ ♦/ ♦|'),
  //   'Straight': fromStringToCard('A /2 /3 /4 /5 |'),
  //   'Three of a kind': fromStringToCard('A /A /A_|2 /3 '),
  //   'Two pair': fromStringToCard('J /J_/3 /3_|2 '),
  //   'One pair': fromStringToCard('A /A |5 /9 /4 '),
  //   'High card': fromStringToCard('A |3 /6 /8 /Q '),
  // }
  return {
    'Straight flush': fromStringToCard('9♠︎/8♠︎/7♠︎/6♠︎/5♠︎|'),
    '4 of a kind': fromStringToCard('A♠︎/A♣/A♥/A♦|8♠︎'),
    'Full house': fromStringToCard('A♠︎/A♣/A♥/2♦/2♠︎|'),
    'Flush': fromStringToCard('K♦/9♦/7♦/4♦/2♦|'),
    'Straight': fromStringToCard('A♠︎/2♣/3♦/4♦/5♠︎|'),
    '3 of a kind': fromStringToCard('A♠︎/A♣/A♥|2♦/3♠︎'),
    'Two pair': fromStringToCard('A♠︎/A♣/J♦/J♠︎|2♦'),
    'One pair': fromStringToCard('A♠︎/A♣|5♦/9♠︎/4♦'),
    'High card': fromStringToCard('A♠︎|3♣/6♦/8♦/Q♠︎'),
  }
}
