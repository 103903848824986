import React from "react";
import { Table } from "../../../models/poker";
import { ChipAmount } from "../../components";

import './PlayerEdit.scss'

import Toll from '@mui/icons-material/Toll';
import { props } from "ramda";
import { findPlayer } from "../../../models/helpers";
import { parseIntSafe } from "./TableSettings";


export const PlayerEdit = (props: { initialTable: Table, close: () => void, onSave: (_: Table) => void }) => {
  const [table, setTable] = React.useState<Table>(props.initialTable);

  const invalid = false;

  const close = () => {
    setTable(props.initialTable);
    props.close();
  }

  const save = () => {
    if (!invalid) {
      props.onSave(table);
      props.close();
    }
  }

  return (
    <>
      <div className="overlay-common player-edit">
        <h1 className="font-bold text-center text-lg mt-2 mb-3">edit players</h1>
        <div className="player-grid">
          <span className="opacity-50"></span>
          <span className="player flex-1">player</span>
          <span className="input-like"><Toll /></span>
          <span className="col-span-3 font-bold">table position</span>

          {table.players.map((player, i) => (
            <React.Fragment key={i}>
              <span className="opacity-50">#{i + 1}</span>
              <span className="player">{player}</span>
              <input value={table.tableStacks[player] || ''} inputMode="numeric" onFocus={e => e.currentTarget.select()} onInput={e => { table.tableStacks[player] = parseIntSafe(e.currentTarget.value); setTable(table.copy()); }} />
              <button className={`button3 ${isPosition(table, player, 'd') && 'disabled'}`} onClick={() => { set(setTable, player, 'd') }}>D</button>
              <button className={`button3 ${isPosition(table, player, 'sb') && 'disabled'}`} onClick={() => { set(setTable, player, 'sb') }}>SB</button>
              <button className={`button3 ${isPosition(table, player, 'bb') && 'disabled'}`} onClick={() => { set(setTable, player, 'bb') }}>BB</button>
            </React.Fragment>
          ))}
        </div>

        <div className="mt-10 mb-4 opacity-75 text-sm text-center">
          <div className="mt-2">
            D: Dealer/Button, SB: Small blind, BB: Big blind
          </div>
          <div className="mt-2">
            To reorder players: drag on the table view
          </div>
          <div className="mt-2">
            To remove players: drag to the trash area
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div className="button3 w-full" onClick={close}>Close</div>
        <div className={`button3 w-full green ${invalid && 'disabled'}`} onClick={save}>Save</div>
      </div>
    </>
  )
}

function isPosition(table: Table, player: string, position: 'sb' | 'bb' | 'd') {
  if (position === 'd') return table.buttonPlayer === player;
  if (table.buttonPlayer === undefined) return false;
  return findPlayer(table.players, table.buttonPlayer, position) === player;
}

function set(setTable: React.Dispatch<React.SetStateAction<Table>>, player: string, position: 'sb' | 'bb' | 'd') {
  setTable((table: Table) => {
    if (position === 'd') {
      table.buttonPlayer = player;
    } else {
      for (let i = 0; i < table.players.length; i++) {
        if (findPlayer(table.players, table.players[i], position) === player) {
          table.buttonPlayer = table.players[i];
          break;
        }
      }
    }
    return table.copy();
  })
}
