import { Table } from "../poker";
import { TableId } from "../types";
import { PokerHandlers } from "./types";


export default class PubSub {
  private tableIdToListOfHandlers: Record<TableId, PokerHandlers[]> = {};

  register(tableId: TableId, handlers: PokerHandlers) {
    this.tableIdToListOfHandlers[tableId] ??= [];
    this.tableIdToListOfHandlers[tableId].push(handlers);
  }

  publish(tableId: TableId, value: Table) {
    (this.tableIdToListOfHandlers[tableId] ?? []).forEach((handlers) => {
      handlers.onUpdate(value.copy());
    });
  }

  remove(tableId: TableId, handlers: PokerHandlers) {
    if (this.tableIdToListOfHandlers[tableId]) {
      this.tableIdToListOfHandlers[tableId] = this.tableIdToListOfHandlers[tableId].filter(v => v !== handlers);
    }
  }
}
