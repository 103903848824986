import React from "react";
import { PLAYER_SIZE, portalForIf } from "../types";

import { Table } from "../../../models/poker";
import { Sprit } from "../../components";
import { PlayerCardForShowdown } from "../PlayerCard";

import { Box } from '@flatten-js/core';
import { ChipAmount as ChipAmountType } from "../../../models/types";
import Assert from "../../../models/assert";


import { SceneProps, SceneInterface } from '../types'
import { SpritView } from "../SpritView";
import { GlobalSettings } from "../GlobalSettings";
import { CentralManager } from "../CentralManager";

interface State {
  sceneLoaded: boolean,
  overlay: 'global' | null,
}

export async function layoutPlayersForResult(sprits: Sprit[], canvas: Box) {
  const numberOfColumns = { 1: 1, 3: 3 }[sprits.length] ?? 2;
  const gap = 20;

  if (numberOfColumns === 1) {
    await sprits[0].animateC(canvas.center);

  } else if (numberOfColumns === 3) {
    await Promise.all([
      sprits[0].animateC(canvas.center.translate(-PLAYER_SIZE.x - gap, 0)),
      sprits[1].animateC(canvas.center.translate(0, 0)),
      sprits[2].animateC(canvas.center.translate(+PLAYER_SIZE.x + gap, 0)),
    ]);

  } else if (sprits.length === 2) {
    await Promise.all([
      sprits[0].animateC(canvas.center.translate(-PLAYER_SIZE.x / 2 - gap / 2, 0)),
      sprits[1].animateC(canvas.center.translate(+PLAYER_SIZE.x / 2 + gap / 2, 0)),
    ]);

  } else {
    const totalHeight = Math.ceil(sprits.length / 2) * (PLAYER_SIZE.y + gap) - gap - PLAYER_SIZE.y; // total height from center to center
    const totalWidth = 2 * PLAYER_SIZE.x + gap - PLAYER_SIZE.x; // total width from center to center
    await Promise.all(
      sprits.map((sprit, i) => {
        let x;
        if (i === sprits.length - 1 && i % 2 === 0) {
          x = 0;
        } else {
          x = i % 2 === 0 ? (-totalWidth / 2) : (+totalWidth / 2);
        }
        const y = Math.floor(i / 2) * (PLAYER_SIZE.y + gap) - totalHeight / 2;
        return sprit.animateC(canvas.center.translate(x, y));
      })
    )
  }
}

export class ResultView extends React.Component<SceneProps, State> implements SceneInterface {
  canvas: Box;
  table: Table;
  cm: CentralManager;
  sv: SpritView;


  setStateAsync = (state: any) => new Promise<void>((resolve) => this.setState(state, resolve));

  state: State = {
    sceneLoaded: false,
    overlay: null,
  }

  get g() {
    Assert.expect(this.table.currentGame).beTruthy('game must be present');
    Assert.expect(this.table.currentGame?.currentRound === 'showdown').beTruthy('game must be in showdown');
    Assert.expect(this.table.currentGame?.isAllPotsSettled).beTruthy('all pots must be settled');
    return this.table.currentGame!;
  }

  constructor(props: SceneProps) {
    super(props);
    this.canvas = props.initialCanvas;
    this.table = props.initialTable;
    this.cm = props.cm;
    this.sv = props.spritView;
  }

  winnerChipMap: { [player: string]: ChipAmountType } = {};
  winnerSprits: Sprit[] = [];

  sceneLoad = async () => {
    this.winnerChipMap = this.g.getWinnerChipMap();

    this.sv.batchStart();
    this.winnerSprits = Object.keys(this.winnerChipMap).map(player => {
      return this.sv.findOrCreate(
        { player },
        {
          size: PLAYER_SIZE,
          ifNew: sprit => { sprit.cAt(this.canvas.center) },
          // ifOld: sprit => sprit.animateToVariant('winner'),
          initialVariant: 'normal',
          variants: {
            normal: {
              bg: '#00000000'
            },
            winner: {
              bg: '#22c55e19'
            },
          }
        }
      );
    });
    await this.sv.batchEnd();
    await this.setStateAsync({ sceneLoaded: true });
  };

  sceneLayout = async (canvas: Box, smooth: boolean) => {
    this.canvas = canvas;
    await Promise.all([
      ...this.winnerSprits.map(sprit => sprit.animateToVariant('winner')),
      layoutPlayersForResult(this.winnerSprits, this.canvas),
    ])
  }

  sceneUnload = async () => {
    await this.setStateAsync({ sceneLoaded: false });
    await Promise.all(this.winnerSprits.map(sprit => sprit.resetVariant()));
  };

  onTableUpdate = async (table: Table) => {
    this.table = table;
    await this.sceneLayout(this.canvas, true);
  }

  goToTable = async () => {
    this.table.completeCurrentGame();
    await this.cm.triggerTableUpdate(this.table, 'finish game');
  }

  nextGame = async () => {
    this.table.completeCurrentGame();
    this.table.currentGame = this.table.initializeNewGame();
    await this.cm.triggerTableUpdate(this.table, 'start new game');
  }

  canStartNewGame = () => {
    try {
      // NOTE: initially this will error because table is outdated?
      const t = this.table.copy();
      t.completeCurrentGame();
      // TODO[Poker]: Make this more robust
      return t.canStartNewGame();
    } catch (e) {
      return false;
    }
  }

  showOverlay = async (view: State['overlay']) => {
    if (view) {
      await this.setStateAsync({ overlay: view });
      await this.cm.setOverlayVisibility(true);
    } else {
      await this.cm.setOverlayVisibility(false);
    }
  }


  render() {
    const { GlobalButton } = this.props;
    const portalFor = portalForIf(this.props.uniqueViewId, this.state.sceneLoaded);

    return [
      portalFor('view-before')(
        <div className="view-before">
          <div className="text-and-settings">
            <GlobalButton onClick={() => this.showOverlay('global')} />
            <div className="prompt-text">Winner!</div>
          </div>
        </div>
      )
      ,
      portalFor('view-after')(
        <div className="grid grid-cols-2 gap-2 px-2 justify-items-center">
          <div className="button3 w-full col-auto" onClick={() => this.goToTable()}>go to table</div>
          {
            this.canStartNewGame() &&
            <div className={`button3 w-full col-span-1 green`} onClick={() => this.nextGame()}>next game</div>
          }
        </div>
      )
      ,
      ...this.winnerSprits.map((sprit, i) => {
        const player = (sprit.id as { player: string }).player;
        const chipAmount = this.winnerChipMap[player];
        return (
          portalFor(sprit)(
            <PlayerCardForShowdown
              player={player}
              winner={true}
              chipsGain={chipAmount}
            />
          )
        );
      })
      ,
      portalFor('overlay')(
        <>
          {
            this.state.overlay === 'global' &&
            <GlobalSettings sharedOptions={this.props.globalSettings} close={() => this.showOverlay(null)}>

            </GlobalSettings>
          }
        </>
      )
    ];
  }
}
