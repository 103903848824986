import { QrCodeSvg } from "../../../lib/QRCode"

export const TableQRCode = (props: { linkToJoin: string, close: () => void }) => {

  return (
    <>
      <div className="overlay-common-qrcode">
        <h1 className="font-bold text-center text-lg mt-2 mb-3">scan the code below to join</h1>
        <QrCodeSvg text={props.linkToJoin} fgColor='var(--text)' bgColor='transparent' />
        <h1 className="font-bold text-center text-lg mt-2 mb-3">{props.linkToJoin}</h1>
      </div>
      <div className="button3 w-full" onClick={() => props.close()}>Close</div>
    </>
  )
}
