import { AnimatePresence, motion } from "framer-motion";
import React from "react";

enum Suit {
  club = '♣',
  spade = '♠︎',
  heart = '♥',
  diamond = '♦',
  blank = ' ',  // '★' or '☁︎'
  blankRed = '_',
}

enum Rank {
  $ = ' ',
  $A = 'A',
  $2 = '2',
  $3 = '3',
  $4 = '4',
  $5 = '5',
  $6 = '6',
  $7 = '7',
  $8 = '8',
  $9 = '9',
  $10 = '10',
  $J = 'J',
  $Q = 'Q',
  $K = 'K',
}

export interface Card {
  suit: Suit;
  rank: Rank;
}

export default function PokerCard(props: { card: Card }) {
  const { rank, suit } = props.card;
  return (
    <div className={`poker-card-size poker-card ${[Suit.diamond, Suit.heart, Suit.blankRed].includes(suit!) && 'red'}`}>
      <div className={`rank ${rank === '10' && 'is-10'}`}>{rank}</div>
      <div className="suit">{suit.replace('_', '')}</div>
    </div>
  );
}

function PokerCardPlaceholder() {
  return <div className={`poker-card-size border-2 border-dashed border-white dark:border-black border-opacity-50 dark:border-opacity-50`} />
}

function PokerCardFacedown() {
  return <div className={`poker-card-size poker-card facedown`} />
}

function PokerCardBlank() {
  return <PokerCard card={{ suit: Suit.blank, rank: Rank.$ }} />
}

PokerCard.Placeholder = PokerCardPlaceholder;
PokerCard.Blank = PokerCardBlank;
PokerCard.Facedown = PokerCardFacedown;
PokerCard.c1 = { suit: Suit.club, rank: Rank.$A };
PokerCard.c2 = { suit: Suit.heart, rank: Rank.$2 };
PokerCard.Suit = Suit;
PokerCard.suits = [Suit.blank, Suit.club, Suit.spade, Suit.heart, Suit.diamond] as Suit[];
PokerCard.Rank = Rank;
PokerCard.ranks = ['', 'A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'] as Rank[];
PokerCard.blank = { suit: Suit.blank, rank: Rank.$ };


export function CardContainer(props: { children: React.ReactNode, noBg?: boolean }) {
  return <div className={`flex flex-wrap gap-1 p-1 rounded-lg ${props.noBg || "bg-neutral-500"} bg-opacity-50 w-fit`}>{props.children}</div>;
}

export function PlayerCards(props: { state: 'none' | 'hidden' | 'visible', cardA: Card, cardB: Card }) {
  return (
    <CardContainer noBg={true}>
      {
        props.state === 'none' ? (
          <>
            <PokerCard.Placeholder />
            <PokerCard.Placeholder />
          </>
        ) : props.state === 'hidden' ? (
          <>
            <PokerCard.Facedown />
            <PokerCard.Facedown />
          </>
        ) : (
          <>
            <PokerCard card={props.cardA} />
            <PokerCard card={props.cardB} />
          </>
        )
      }
    </CardContainer>
  );
}

export function CommunityCards(props: { cards: Card[], blank?: boolean }) {
  const bd = 0.5; // base delay
  return (
    <div className="relative">
      <CardContainer>

        {/* Vchips.app watermark on cards */}
        {
          props.blank ? (
            <div className="font-mono absolute opacity-50 z-10 w-full h-full flex items-center justify-between text-lg text-neutral-500 pl-0.5 pr-2.5 top-0" style={{ letterSpacing: 2 }}>
              {'vc hi ps .a pp'.split(' ').map((c, i) =>
                <div className="relative" key={i}>
                  <div className="invisible">{c}</div>
                  {
                    i >= props.cards.length || (
                      <>
                        <motion.div transition={{ delay: bd + 1.5, duration: 0.5 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="absolute top-0 left-0">
                          {c}
                        </motion.div>
                        <motion.div transition={{ delay: bd + 1.5, duration: 0.75 }} initial={{ opacity: 0, x: 0, y: 0 }} animate={{ scale: 1.01, opacity: 1, x: -1, y: -1 }} className="absolute top-0 left-0 text-white dark:text-black">
                          {c}
                        </motion.div>
                      </>
                    )
                  }
                </div>
              )
              }
            </div>
          ) : null
        }

        {
          Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="relative">
              <PokerCard.Placeholder />
              <AnimatePresence>
                {
                  props.cards.length <= index ? null :
                    (
                      <React.Fragment key={index}>
                        <motion.div className="absolute top-0" transition={{ delay: bd + 0, duration: props.blank ? 0.5 : 0.1, bounce: true }} initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 0.95 }} exit={{ scale: 0, opacity: 0 }}>
                          <PokerCard.Facedown />
                        </motion.div>
                        <motion.div className="absolute top-0" transition={{ delay: bd + (props.blank ? 1 : 0.5), duration: 0.5, bounce: Boolean(props.blank) }} initial={{ opacity: 0, scale: 0.95 }} animate={{ opacity: 1, scale: 0.95 }} exit={{}}>
                          <PokerCard.Blank />
                        </motion.div>
                        <motion.div className="absolute top-0" transition={{ delay: bd + 1, duration: 1, type: 'tween' }} initial={{ opacity: 0, scale: 1 }} animate={{ opacity: 1, scale: 1 }} exit={{}}>
                          <PokerCard card={props.cards[index]} />
                        </motion.div>
                      </React.Fragment>
                    )
                }
              </AnimatePresence>
            </div>
          ))
        }
      </CardContainer>
    </div>
  );
}



