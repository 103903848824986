import React from "react";
import { PLAYER_SIZE, portalForIf } from "../types";

import { Table } from "../../../models/poker";
import { ChipAmount } from "../../components";
import { PlayerCardForShowdown } from "../PlayerCard";

import { Box } from '@flatten-js/core';
import Assert from "../../../models/assert";


import { SceneProps, SceneInterface } from '../types'
import { SpritView } from "../SpritView";
import { GlobalSettings } from "../GlobalSettings";
import { CentralManager } from "../CentralManager";

interface State {
  sceneLoaded: boolean,
  overlay: 'global' | null,
}

export class ShowdownComplexView extends React.Component<SceneProps, State> implements SceneInterface {
  canvas: Box;
  table: Table;
  cm: CentralManager;
  sv: SpritView;

  setStateAsync = (state: any) => new Promise<void>((resolve) => this.setState(state, resolve));

  state: State = {
    sceneLoaded: false,
    overlay: null,
  }

  get g() {
    Assert.expect(this.table.currentGame).beTruthy();
    return this.table.currentGame!;
  }

  get player() { return this.props.playerId; }

  constructor(props: SceneProps) {
    super(props);
    this.canvas = props.initialCanvas;
    this.table = props.initialTable;
    this.cm = props.cm;
    this.sv = props.spritView;
  }


  sceneLoad = async () => {
    await this.cm.setCanvasVisibility(false);
    await this.setStateAsync({ sceneLoaded: true });
  };

  sceneLayout = async (canvas: Box, smooth: boolean) => {
    this.canvas = canvas;
  }


  sceneUnload = async () => {
    await this.cm.setCanvasVisibility(true);
  };

  onTableUpdate = async (table: Table) => {
    this.table = table;
    await this.sceneLayout(this.canvas, true);
  }

  continue = async () => {
    if (!this.g.isAllPotsSettled) return;
    await this.cm.triggerTableUpdate(this.table, 'select winners');
  }

  showOverlay = async (view: State['overlay']) => {
    if (view) {
      await this.setStateAsync({ overlay: view });
      await this.cm.setOverlayVisibility(true);
    } else {
      await this.cm.setOverlayVisibility(false);
    }
  }

  render() {
    const { GlobalButton } = this.props;
    const portalFor = portalForIf(this.props.uniqueViewId, this.state.sceneLoaded);

    return [
      portalFor('view-before')(
        <div className="view-before">
          <div className="text-and-settings">
            <GlobalButton onClick={() => this.showOverlay('global')} />
            <div className="prompt-text">Select winner for each pot</div>
          </div>
        </div>
      )
      ,
      portalFor('view-center')(
        <div className="flex-1 overflow-auto relative z-[200]">
          <div className="grid grid-cols-1 gap-4 px-2 justify-items-center">
            {
              this.g.pots.map((pot, potIndex) => {
                return (
                  <div key={potIndex} className="">
                    <div className="w-full text-center my-2">Pot {potIndex + 1} <span className="opacity-50"> – <ChipAmount doNotAnimate amount={this.g.pots[potIndex].amount} /></span></div>
                    <div className="flex flex-wrap gap-2 px-2 justify-center">
                      {
                        pot.players.map(player =>
                        (
                          <div
                            key={player}
                            className="cursor-pointer rounded border"
                            style={{ width: PLAYER_SIZE.x, height: PLAYER_SIZE.y }}
                            onClick={() => {
                              const potResult = this.g.potResult || [];
                              if (potResult[potIndex].includes(player)) {
                                potResult[potIndex] = potResult[potIndex].filter(p => p !== player);
                              } else {
                                potResult[potIndex].push(player);
                              }
                              this.forceUpdate();
                            }}
                          >
                            <PlayerCardForShowdown
                              player={player}
                              winner={(this.g.potResult || [])[potIndex].includes(player)}
                            />
                          </div>
                        )
                        )
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>
      )
      ,
      portalFor('view-after')(
        <div className="grid gap-2 px-2 justify-items-center">
          <div className={`button3 green w-full ${this.g.isAllPotsSettled || 'disabled'}`} onClick={() => this.continue()}>save & continue</div>
        </div>
      )
      ,
      portalFor('overlay')(
        <>
          {
            this.state.overlay === 'global' &&
            <GlobalSettings sharedOptions={this.props.globalSettings} close={() => this.showOverlay(null)}>

            </GlobalSettings>
          }
        </>
      )
    ];
  }
}
